import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueLazyload from "vue-lazyload";
import i18n from "./i18n";
import VueGtag from "vue-gtag";
import VueYouTubeEmbed from "vue-youtube-embed";

Vue.use(VueYouTubeEmbed);

Vue.use(VueGtag, {
  config: { id: "G-3KNH3PN4FR" },
});

Vue.use(VueLazyload);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
