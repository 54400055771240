<template>
  <div id="menu">
    <div :class="{ 'logo-wrapper-hidden': !isOnStartPage }" class="logo-wrapper" @click="routeToStartPage" tabindex="0">
      <img src="../assets/images/menu_logo.png" alt="logo" class="logo"/>
      <p class="logo-text">3D Pack Pro</p>
    </div>
    <div class="others-page-logo-wrapper">
      <div :class="{ hidden: isOnStartPage }" class="logo-wrapper" @click="routeToStartPage" tabindex="0">
        <img src="../assets/images/menu_logo.png" alt="logo" class="logo"/>
        <p class="logo-text">3D Pack Pro</p>
      </div>
      <router-link :class="{ hidden: isOnStartPage }" to="/" tabindex="-1">
        <button class="menu__back-btn">BACK</button>
      </router-link>
    </div>
    <div class="menu__text__wrapper">
      <div class="menu__text">
        <p class="text-animation">
          web to print solution for packaging industries
        </p>
      </div>
    </div>
    <router-link class="logo-wrapper-hidden" :class="{ hidden: isOnStartPage }" to="/" tabindex="-1">
      <button class="menu__back-btn">BACK</button>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOnStartPage: this.$route.name === "StartPage",
    };
  },

  methods: {
    routeToStartPage() {
      this.$router.push({path: "/"}).catch((err) => {
      });
    },
  },
};
</script>

<style lang="scss">
#menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 78%;
  padding-top: 16px;
  margin: 0 auto 140px;


  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 188px;
    height: 48px;
    cursor: pointer;
    outline: none;

    .logo {
      width: 48px;
      height: 48px;
    }

    .logo-text {
      font-family: $baseFontFamily;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #18191f;
      width: 150px;
      margin-left: 16px;
    }

    &:hover {
      .logo-text {
        color: #7e39ff;
      }
    }

    &:focus {
      .logo-text {
        color: #7126ff;
      }
    }
  }

  .menu__text__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .menu__text {
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      font-family: $baseFontFamily;
      color: #929aab;

      .text-animation {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;

        animation: animateMenuText 3s infinite;
      }
    }
  }

  .hidden {
    display: none
  }

  .menu__back-btn {
    z-index: 1;
    position: relative;
    padding: 10px 30px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(40px);
    border-radius: 100px;
    border: 2px solid transparent;
    outline: none;

    font-family: $baseFontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;

    color: #929aab;

    &:hover {
      @include gray-btn_hover;
    }

    &:focus {
      @include gray-btn_focus;
    }
  }

  .others-page-logo-wrapper {
    display: none;
  }
}

@keyframes animateMenuText {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  #menu {
    width: 91.8%;
    margin-bottom: 134px;

    .hidden {
      opacity: 0;
      display: block;
    }
  }
}

@media screen and (max-width: 869px) {
  #menu {
    display: flex;
    flex-direction: column-reverse;
    width: 88.3%;
    padding-top: 0;
    margin-bottom: 70px;

    .logo-wrapper {
      align-self: flex-start;
      margin-top: 12px;
    }

    .logo-wrapper-hidden {
      display: none;
    }

    .menu__text__wrapper {
      display: flex;
      width: 100vw;
      background-color: rgba(255, 255, 255, 0.5);
      justify-content: center;
      padding: 3px;

      .menu__text {
        font-size: 13px;
        margin-left: 10px;
      }
    }

    .others-page-logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 510px) {
  #menu {
    width: 91.2%;

    .menu__text__wrapper {
      width: 358px;
      left: -14px;
    }

    .menu__back-btn {
      font-size: 0;
      padding: 0;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background-image: url(../assets/images/back_btn_arrow.svg);
      background-position: 15px;
      background-repeat: no-repeat;
    }

    .hidden {
      display: none;
    }
  }
}

@media screen and (min-width: 1024px) {
  #menu {
    width: 91%;

    .hidden {
      opacity: 0;
      display: block;
    }
  }
}

@media screen and (min-width: 1440px) {
  #menu {
    width: 78%;
  }
}
</style>
