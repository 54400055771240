<template>
  <div id="start-selling">
    <div class="header-wrapper">
      <header class="header">
        <div class="improve_selling_image"></div>
        <div class="blue"></div>
        <div class="big_purple"></div>
        <div class="small_purple"></div>
        <div class="package"></div>
        <div class="small_orange"></div>
        <div class="big_orange"></div>
        <Menu/>
        <div class="title-wrapper">
          <h1 class="title">
            <span class="title_purple">Improve online sales</span> of your
            packaging!
          </h1>
          <div class="buttons-wrapper">
            <a
                target="_blank"
                href="https://3dpack.codex-soft.net/#/auth"
                class="get-access-btn"
            >
              Get started now
            </a>
            <div class="play_btn_image" @click="toggleModal"></div>
            <VideoModal v-if="isModalOpened" @close-modal="toggleModal" videoSrc="vYPqfs0RI_k"/>
          </div>
        </div>
        <h2 class="title2">
          Selling packaging online seems
          <span class="title2_purple">a task too hard?</span>
        </h2>
      </header>
    </div>
    <main class="main">
      <div class="selling-problems-wrapper">
        <div class="selling-problems">
          <div v-if="pageSize > 1023">
            <div class="problem" v-for="(problem, i) of problems" :key="i">
              {{ problem }}
            </div>
          </div>
          <div class="carousel-container" v-else>
            <carousel
                :navigationEnabled="true"
                :navigationNextLabel="' '"
                :navigationPrevLabel="' '"
                :per-page="1"
                :paginationActiveColor="'#9055ff'"
                :paginationColor="'#c8cdd8'"
                :paginationSize="8"
                :autoplayTimeout="3000"
                :centerMode="true"
            >
              <slide v-for="(problem, i) of problems" :key="i">
                {{ problem }}
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <div class="container-wrapper">
        <section class="container" id="about">
          <section class="solution">
            <div class="solution-image__wrapper">
              <img
                  src="../../assets/images/solution_image.png"
                  alt="solution"
                  class="solution-image"
              />
            </div>
            <div class="solution-wrapper">
              <h2 class="solution__title">
                We have <span class="title__purple">a solution!</span>
              </h2>
              <p class="solution__text">
                3D Pack Pro - web to print solution for packaging industries that
                allows you to expand coverage of customers, get new clients, and
                let them create boxes to their needs. Create packaging and
                integrate it seamlessly. If you have any questions you can contact
                our sales at any time.
              </p>
              <router-link to="/get-in-touch" tabindex="-1">
                <button
                    class="contact-sales-btn"
                    @click="trackClick('click Contact Our Sales button')"
                >
                  Contact Our Sales
                </button>
              </router-link>
            </div>
          </section>
          <section class="solvable-problems">
            <h2 class="title">
              <span class="title__purple">3D Print Pro</span> helps solve the
              problems with...
            </h2>
            <div class="card-wrapper">
              <ProblemCard
                  v-for="card of problemCards"
                  :key="card.image"
                  :title="card.title"
                  :text="card.text"
                  :image="card.image"
                  :backgroundColor="card.backgroundColor"
              />
            </div>
          </section>
          <PackContainer/>
          <FAQ/>
        </section>
      </div>
      <section class="get-access">
        <h2 class="title">
          <span class="title__purple">Get your business up</span> and running
          with <br/>
          3D Pack Pro!
        </h2>
        <a
            target="_blank"
            href="https://3dpack.codex-soft.net/#/auth"
            class="get-access-btn"
        >
          Get started now
        </a>
      </section>
      <Footer/>
    </main>
  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

import Menu from "../Menu";
import ProblemCard from "./ProblemCard";
import FAQ from "../FAQ";
import Footer from "../Footer";
import PackContainer from "../PackContainer";
import VideoModal from "../VideoModal";

import {track} from "../../scripts/gtag";

export default {
  created() {
    window.addEventListener("resize", this.pageResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.pageResize);
  },

  methods: {
    pageResize() {
      this.pageSize = window.innerWidth;
    },

    trackClick(str) {
      track.call(this, str);
    },

    toggleModal() {
      this.isModalOpened = !this.isModalOpened;
      if (this.isModalOpened) {
        // document.body.classList.add('modal-open');
        let x=window.scrollX;
        let y=window.scrollY;
        window.onscroll=function(){window.scrollTo(x, y);};

      } else {
        // document.body.classList.remove('modal-open');
        window.onscroll=function(){};

      }
    }
  },

  data() {
    return {
      pageSize: window.innerWidth,
      isModalOpened: false,

      problems: [
        "The storefront consumes a lot of resources from the user's computer",
        "Creating a  template requires special skills and pricey software",
        "The customized templates are of subpar quality",
        "Setting up an online shop seems too expensive",
        "Creating a product listing takes too much time",
        "The bugs in storefront software decrease customer satisfaction",
        "The storefront looks bad",
      ],

      problemCards: [
        {
          title: "Storefront",
          text:
              "Discover solution that allows to increase the number of your customers via a new sales channel. Let your clients see the layout of packages and design them for purchase.",
          image: "card1",
          backgroundColor: "#FBEEB9",
        },
        {
          title: "Admin Side",
          text:
              "Configure, customize and propose 2D and 3D boxes and flexible packages of various shapes in a handy editor and get a ready-to-print layout.",
          image: "card2",
          backgroundColor: "rgba(157, 222, 244, 0.4)",
        },
        {
          title: "Financial aspects",
          text:
              "Sell your boxes and flex packaging with 3D Pack Pro adapted to your needs. Using  3D Pack Pro is more beneficial than developing a solution from scratch.",
          image: "card3",
          backgroundColor: "rgba(249, 118, 0, 0.3)",
        },
      ],
    };
  },

  components: {
    Menu,
    ProblemCard,
    FAQ,
    Footer,
    Carousel,
    Slide,
    PackContainer,
    VideoModal
  },
};
</script>

<style lang="scss">
#start-selling {
  body.modal-open {
    overflow: hidden;
  }
  .header {
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 203px;
    background-size: 100%;

    .title-wrapper {
      width: 71.2%;
      margin: 0 auto 390px;
      padding-right: 520px;
      text-align: left;

      .title {
        @include heading1_black;

        margin-bottom: 32px;

        .title_purple {
          @include heading1_purple;
        }
      }

      .buttons-wrapper {
        display: flex;
        .get-access-btn {
          @include main-btn__black;
          text-decoration: none;

          padding: 15px 44px;
          margin-right: 24px;

          &:hover {
            @include main-btn_hover;
          }

          &:focus {
            @include main-btn_focus;
          }
        }
        .play_btn_image {
          width: 56px;
          height: 56px;
          background-image: url(./../../assets/images/btn_how_it_works.png);
          cursor: pointer;
          position: relative;
          display: inline;
          &:hover {
            background-image: url(./../../assets/images/btn_how_it_works_hover.png);
          }
          &:hover:after{
          @include tooltip_for_video;
          }
        }
      }
    }

    .title2 {
      @include heading2_black;

      width: 43%;
      max-width: 617px;
      margin: 0 auto 20px;
      text-align: center;

      .title2_purple {
        @include heading2_purple;
      }
    }
  }

  .main {
    background-color: #d8f2fb;

    .selling-problems {
      position: relative;
      width: 100%;
      height: 319px;
      background-image: url(../../assets/images/thinking_man.png);
      background-size: 450px 450px;
      background-repeat: no-repeat;
      background-position: 50% -130px;

      .carousel-container {
        width: 700px;
        margin: 0 auto;
        position: relative;
        top: -56px;

        .VueCarousel {
          width: 700px;
        }

        .VueCarousel-wrapper {
          width: 502px;
          margin: 0 auto;
          box-shadow: 0 5px 30px rgba(144, 85, 255, 0.2);
          border-radius: 12px;
        }

        .VueCarousel-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 88px;
          padding: 0 87px;

          background-color: #ffffff;

          @include main-text;
        }

        .VueCarousel-navigation-button {
          top: 79%;
          width: 24px;
          height: 24px;
          background-image: url(../../assets/images/carousel_arrow.svg);
          transform: none;
          outline: none;
        }

        .VueCarousel-navigation-next {
          right: 12px;
        }

        .VueCarousel-navigation-prev {
          left: 12px;

          transform: rotate(180deg);
        }

        .VueCarousel-dot-container {
          margin-top: -11px !important;
        }

        .VueCarousel-dot {
          outline: none;
        }
      }

      .problem {
        @include main-text;

        position: absolute;
        padding: 20px 32px;
        background: #ffffff;
        box-shadow: 0 5px 30px rgba(144, 85, 255, 0.2);
        border-radius: 12px;
      }

      .problem:nth-child(1) {
        top: -140px;
        left: calc(50% - 572px);
      }

      .problem:nth-child(2) {
        top: -54px;
        left: calc(50% + 62px);
      }

      .problem:nth-child(3) {
        top: 63px;
        left: calc(50% + 123px);
      }

      .problem:nth-child(4) {
        top: 183px;
        left: calc(50% + 199px);
      }

      .problem:nth-child(5) {
        top: 211px;
        left: calc(50% - 602px);
      }

      .problem:nth-child(6) {
        top: 75px;
        left: calc(50% - 675px);
      }

      .problem:nth-child(7) {
        top: -28px;
        left: calc(50% - 389px);
      }
    }


    //.container-wrapper {
    .container {
      max-width: 1440px;
      margin: 0 auto;
      padding-top: 280px;
      background-image: url(../../assets/images/container_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .solution {
        display: flex;
        justify-content: center;
        margin: 0 auto 230px;
        width: 71.1%;

        .solution-image__wrapper {
          min-width: 435px;
          width: 435px;
          height: 347px;

          .solution-image {
            width: 100%;
            height: 100%;
          }
        }

        .solution-wrapper {
          width: 502px;
          margin-left: 87px;

          .solution__title {
            margin-bottom: 21px;

            @include heading2_black;

            .title__purple {
              @include heading2_purple;
            }
          }

          .solution__text {
            @include main-text;

            margin-bottom: 95px;
          }

          .contact-sales-btn {
            @include main-btn__black;

            padding: 15px 35px;

            &:hover {
              @include main-btn_hover;
            }

            &:focus {
              @include main-btn_focus;
            }
          }
        }
      }

      .solvable-problems {
        .title {
          @include heading2_black;

          width: 600px;
          margin: 0 auto 74px;
          text-align: center;

          .title__purple {
            @include heading2_purple;
          }
        }

        .card-wrapper {
          display: flex;
          margin: 0 auto 262px;
          width: 71.1%;
        }
      }

      #pack-container {
        margin-bottom: 203px;
      }

      #faq {
        padding: 53px 0 104px;
      }
    }

    //}

    .get-access {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 125px 0 121px;
      background-color: #d8f2fb;
      text-align: center;

      .title {
        @include heading2_black;

        width: 560px;
        margin-bottom: 32px;

        .title__purple {
          @include heading2_purple;
        }
      }

      .get-access-btn {
        @include main-btn__black;
        text-decoration: none;

        padding: 15px 44px;

        &:hover {
          @include main-btn_hover;
        }

        &:focus {
          @include main-btn_focus;
        }
      }
    }
  }
}


@media screen and (min-width: 360px) {
  #start-selling {
    .header {
      padding-bottom: 112px;
      background-image: url(../../assets/images/header_bg/header_bg_360.png);

      .package {
        position: absolute;
        right: 0;
        top: 727px;
        width: 153px;
        height: 180px;
        background-image: url(../../assets/images/improve_sales/package.png);
      }

      .blue {
        position: absolute;
        left: 40px;
        top: 644px;
        width: 48px;
        height: 48px;
        background-image: url(../../assets/images/circles/blue_circle_48.png);
      }

      #menu {
        margin: 0 auto 135px;
      }

      .title-wrapper {
        width: 91%;
        padding-right: 0;
        margin: 0 auto 408px;

        .title {
          @include heading2_black;

          .title_purple {
            @include heading2_purple;
          }
        }
      }

      .title2 {
        width: 100%;
        font-size: 32px;
        line-height: 40px;
        margin: 0 auto;
        padding-bottom: 20px;

        .title2_purple {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }

    .main {
      .selling-problems {
        background-size: 360px 360px;
        background-position: 50% -41px;

        .carousel-container {
          width: 328px;
          top: -81px;

          .VueCarousel {
            width: 328px;
          }

          .VueCarousel-slide {
            height: 112px;
            padding: 0 32px;
          }

          .VueCarousel-wrapper {
            width: 328px;
          }

          .VueCarousel-navigation-button {
            top: 83%;
          }

          .VueCarousel-navigation-next {
            display: none;
            right: 0;
          }

          .VueCarousel-navigation-prev {
            display: none;
            left: 0;
          }
        }
      }

      .container {
        padding-top: 104px;
        background-image: url(../../assets/images/container_bg_360.png);

        .solution {
          flex-direction: column;
          align-items: center;

          .solution-image__wrapper {
            min-width: 326px;
            width: 326px;
            height: 261px;
            margin-bottom: 36px;
          }

          .solution-wrapper {
            width: 328px;
            margin-left: 0;

            .solution__title {
              margin-bottom: 20px;

              font-size: 32px;
              line-height: 40px;

              .title__purple {
                font-size: 32px;
                line-height: 40px;
              }
            }

            .solution__text {
              width: 328px;
              margin-bottom: 47px;
            }
          }
        }

        .solvable-problems {
          .title {
            margin: 0 auto 31px;
            width: 328px;
            font-size: 32px;
            line-height: 40px;

            .title__purple {
              font-size: 32px;
              line-height: 40px;
            }
          }

          .card-wrapper {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 91%;
          }
        }

        #pack-container {
          margin-bottom: 80px;
        }
      }

      .get-access {
        padding: 67px 0 65px;

        .title {
          width: 360px;
          font-size: 32px;
          line-height: 40px;

          .title__purple {
            font-size: 32px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 510px) {
  #start-selling {
    .header {
      background-image: url(../../assets/images/header_bg/header_bg_510.png);

      .improve_selling_image {
        position: absolute;
        width: 212px;
        height: 279px;
        top: 256px;
        right: 0;
        background-image: url(../../assets/images/improve_sales/improve_online_sales_560.png);
        background-repeat: no-repeat;
      }

      .package {
        top: 627px;
      }

      .blue {
        top: 550px;
        left: 200px;
      }

      .buttons-wrapper {
        .play_btn_image {
          &:hover {
            background-image: url(./../../assets/images/btn_how_it_works_hover.png);
          }
          &:hover:after{
            @include tooltip_for_video;
          }
        }
      }
    }

    .main {
      .selling-problems {
        .carousel-container {
          width: 478px;

          .VueCarousel {
            width: 478px;
          }

          .VueCarousel-slide {
            width: 478px;
          }

          .VueCarousel-wrapper {
            width: 478px;
          }
        }
      }

      .container {
        .solvable-problems {
          width: 100%;

          .card-wrapper {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: center;
            width: 100%;
          }

          .title {
            width: 479px;
          }
        }

        .solution {
          width: 91%;

          .solution-image__wrapper {
            width: 435px;
            height: 347px;
          }

          .solution-wrapper {
            width: 100%;

            .solution__text {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #start-selling {
    .header {

      .title-wrapper {
        margin: 0 auto 658px;

        .title {
          @include heading1_black;
          width: 589px;

          .title_purple {
            @include heading1_purple;
          }
        }
      }

      .package {
        width: 180px;
        height: 180px;
        right: 413px;
        top: 650px;
        background-image: url(../../assets/images/improve_sales/full_package.png);
        background-size: cover;
      }

      .improve_selling_image {
        position: absolute;
        width: 400px;
        height: 850px;
        top: 40px;
        right: 0;
        background-image: url(../../assets/images/improve_sales/improve_online_sales_768.png);
        background-repeat: no-repeat;
        background-size: cover;
      }

      .blue {
        left: 0;
      }

      background-image: url(../../assets/images/header_bg/header_bg_768.png);

      .title2 {
        @include heading2_black;

        .title2_purple {
          @include heading2_purple;
        }
      }
    }

    .main {
      .selling-problems {
        .carousel-container {
          width: 502px;

          .VueCarousel {
            width: 502px;
          }

          .VueCarousel-slide {
            width: 502px;
          }

          .VueCarousel-wrapper {
            width: 502px;
          }
        }
      }

      .container {
        .solution {
          .solution-wrapper {
            width: 502px;

            .solution__title {
              @include heading2_black;

              .title__purple {
                @include heading2_purple;
              }
            }
          }
        }

        .solvable-problems {
          .title {
            width: 589px;
            margin-bottom: 103px;
            @include heading2_black;

            .title__purple {
              @include heading2_purple;
            }
          }
        }
      }

      .get-access {
        .title {
          width: 589px;
          @include heading2_black;

          .title__purple {
            @include heading2_purple;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  #start-selling {
    .header {
      padding-bottom: 230px;
      background-size: 100%;
      background-image: url(../../assets/images/header_bg/header_bg_1024.png);

      .bg-package {
        position: absolute;
        right: 0;
        width: 550px;
        height: 100%;
        background-image: (url(../../assets/images/improve_sales/improve_sales_header_bg_image_1024.png));
        background-size: 100%;
        background-repeat: no-repeat;
      }

      .blue {
        display: none;
      }

      .small_orange {
        position: absolute;
        width: 32px;
        height: 32px;
        left: 487px;
        top: 350px;
        background-image: url(../../assets/images/circles/orange_circle_32.png);
      }

      .big_orange {
        position: absolute;
        width: 48px;
        height: 48px;
        right: 42px;
        top: 959px;
        background-image: url(../../assets/images/circles/orange_circle_48.png);
        background-repeat: no-repeat;
      }

      .small_purple {
        position: absolute;
        top: 1017px;
        right: 199px;
        width: 24px;
        height: 24px;
        background-image: url(../../assets/images/circles/purple_circle_24.png);
      }

      .title-wrapper {
        margin: 0 auto 658px;

        .title {
          width: 589px;
          @include heading1
        }
      }

      .title2 {
        margin: 0 auto 32px;
        @include heading2_black;

        .title2_purple {
          @include heading2_purple;
        }
      }
    }

    .main {
      .selling-problems {
        .problem {
          padding: 20px 32px;
          text-align: center;
        }

        .problem:nth-child(1) {
          top: -256px;
          width: 350px;
          left: calc(50% - 360px);
        }

        .problem:nth-child(2) {
          top: -170px;
          left: calc(50% + 56px);
          width: 330px;
        }

        .problem:nth-child(3) {
          top: -29px;
          left: calc(50% + 117px);
          width: 278px;
        }

        .problem:nth-child(4) {
          top: 115px;
          left: calc(50% + 180px);
          width: 278px;
        }

        .problem:nth-child(5) {
          top: 135px;
          left: calc(50% - 445px);
          width: 270px;
        }

        .problem:nth-child(6) {
          top: -17px;
          width: 320px;
          left: calc(50% - 470px);
        }

        .problem:nth-child(7) {
          top: -120px;
          left: calc(50% - 247px);
        }
      }

      .container {

        .solution {
          flex-direction: row;
          justify-content: flex-start !important;
          align-items: flex-start;

          .solution-image__wrapper {
            width: 393px;
            height: 314px;
            margin-right: 87px;
          }

          .solution-wrapper {

            .solution__title {
              width: 509px;
              @include heading2_black;

              .title__purple {
                @include heading2_purple;
              }
            }

            .solution__text {
              @include main-text;

              margin-bottom: 95px;
            }
          }
        }

        .solvable-problems {
          .title {
            width: 589px;
            margin-bottom: 103px;
            @include heading2_black;

            .title__purple {
              @include heading2_purple;
            }
          }

          .card-wrapper {
            width: 95%;
          }
        }
      }

      .get-access {
        padding: 122px 0 122px;

        .title {
          width: 589px;
          @include heading2_black;

          .title__purple {
            @include heading2_purple;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  #start-selling {
    .header-wrapper {
      background-image: url(../../assets/images/header_bg/header_bg_1440.png);
      background-size: 100%;

      .header {
        padding-bottom: 90px;
        max-width: 1440px;
        margin: 0 auto;
        background-image: none;
        position: relative;

        .improve_selling_image {
          width: 691px;
          height: 1008px;
          top: 0;
          right: 0;
          background-image: url(../../assets/images/improve_sales/improve_online_sales_1440.png);
        }

        .big_orange {

        }

        .small_orange {
          top: 380px;
          left: 657px;
        }

        .blue {
          display: block;
        }

        .small_purple {
          top: 1117px;
          right: 199px;
        }

        .big_purple {
          position: absolute;
          width: 88px;
          height: 88px;
          top: 122px;
          right: 97px;
          background-image: url(../../assets/images/circles/purple_circle_88.png);
        }

        .package {
          top: 727px;
          right: 1053px;
        }

        .blue {
          top: 644px;
          left: 32px;
        }

        .title-wrapper {
          width: 78%;

          .title {
            @include heading1_black;

            margin-bottom: 32px;

            .title_purple {
              @include heading1_purple;
            }
          }
        }

        .title2 {
          @include heading2_black;
          padding-bottom: 64px;

          .title2_purple {
            @include heading2_purple;
          }
        }
      }
    }

    .main {
      .selling-problems-wrapper {
        .selling-problems {
          max-width: 1440px;
          margin: 0 auto;

          .problem {
            @include main-text;

            position: absolute;
            padding: 20px 32px;
            background: #ffffff;
            box-shadow: 0 5px 30px rgba(144, 85, 255, 0.2);
            border-radius: 12px;
          }

          .problem:nth-child(1) {
            width: 612px;
            top: -140px;
            left: calc(50% - 572px);
          }

          .problem:nth-child(2) {
            width: 567px;
            top: -54px;
            left: calc(50% + 62px);
          }

          .problem:nth-child(3) {
            width: 448px;
            top: 63px;
            left: calc(50% + 123px);
          }

          .problem:nth-child(4) {
            width: 440px;
            top: 183px;
            left: calc(50% + 199px);
          }

          .problem:nth-child(5) {
            width: 438px;
            top: 211px;
            left: calc(50% - 602px);
          }

          .problem:nth-child(6) {
            width: 570px;
            top: 75px;
            left: calc(50% - 675px);
          }

          .problem:nth-child(7) {
            top: -28px;
            left: calc(50% - 389px);
          }
        }
      }

      .container-wrapper {
        background-image: url(../../assets/images/container_bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .container {
          padding-top: 180px;
          background-image: none;

          .solution {
            width: 78%;
            justify-content: space-evenly !important;
            align-items: center;

            .solution-image__wrapper {
              width: 435px;
              height: 347px;
            }

            .solution-wrapper {
              width: 502px;

              .solution__title {
                @include heading2_black;

                .title__purple {
                  @include heading2_purple;
                }
              }

              .solution__text {
                width: 487px;
                margin-bottom: 96px;
              }
            }
          }

          .solvable-problems {
            .title {
              min-width: 600px;
              @include heading2_black;

              .title__purple {
                @include heading2_purple;
              }
            }

            .card-wrapper {
              margin: 0 auto 146px;
              width: 90% !important;
            }
          }
        }
      }

      .get-access {
        padding: 122px 0 122px;

        .title {
          width: 589px;
          @include heading2_black;

          .title__purple {
            @include heading2_purple;
          }
        }
      }

      #pack-container {
        margin-bottom: 80px;
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  #start-selling {
    .header-wrapper {
      .header {
        .improve_selling_image {
          width: 930px;
          height: 1008px;
          top: -200px;
          right: -190px;
          background-image: url(../../assets/images/improve_sales/full_boxes.png);
        }

        .big_purple {
          top: 100px;
          right: 330px;
        }
      }
    }
  }
}
</style>
