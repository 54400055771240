<template>
  <div id="improve-sales">
    <div class="header-wrapper">
      <header class="header">
        <div class="start_selling_image"></div>
        <div class="orange"></div>
        <div class="blue"></div>
        <div class="yellow"></div>
        <div class="big_purple"></div>
        <div class="small_purple"></div>
        <Menu/>
        <div class="title-wrapper">
          <h1 class="title">
            <span class="title__purple">Start</span> selling packaging online!
          </h1>
          <div class="buttons-wrapper">
            <a
                target="_blank"
                href="https://3dpack.codex-soft.net/#/auth"
                class="get-access-btn"
            >
              Get started now
            </a>
            <div class="play_btn_image" @click="toggleModal"></div>
            <VideoModal v-if="isModalOpened" @close-modal="toggleModal" videoSrc="vYPqfs0RI_k"/>
          </div>
        </div>
        <div class="card-wrapper">
          <div class="card bg-yellow">
            <h3 class="card-title">Process Automation</h3>
            <p class="card-text">
              Save time and money. Our solution allows you to simplify the process
              of creating packaging starting with choice of a model, sizes and
              options, to design and layout for printing, without requiring
              special skills.
            </p>
          </div>
          <div class="card bg-blue">
            <h3 class="card-title">More Sales</h3>
            <p class="card-text">
              Expand your sales channels with 3D Pack Pro. The new sales channel
              will increase a number of customers, and the joy of using the
              solution will stay with you for a long time.
            </p>
          </div>
        </div>
      </header>
    </div>
    <main class="main">
      <div class="free-propositions-wrapper">
        <section class="free-propositions">
          <div class="box"></div>
          <div class="package"></div>
          <div class="orange"></div>
          <div class="blue"></div>
          <div class="wrapper">
            <h2 class="title">
              Free propositions for
              <span class="title__purple">early adopters</span>
            </h2>
            <p class="text">
              Create packaging and integrate it seamlessly. If you have any
              questions you can contact our sales at any time.
            </p>
            <router-link to="/get-in-touch" tabindex="-1">
              <button
                  class="get-access-btn"
                  @click="trackClick('click Contact Our Sales button')"
              >
                Contact Our Sales
              </button>
            </router-link>
          </div>
        </section>
      </div>
      <div class="container-wrapper">
        <section class="container">
          <Features/>
          <PackContainer/>
          <FAQ/>
        </section>
        <div class="ready-for-success">
          <h2 class="title">
            <span class="title__purple">Ready for success? </span><br>Get started
            with <br/>
            3D Pack Pro today!
          </h2>
          <a
              target="_blank"
              href="https://3dpack.codex-soft.net/#/auth"
              class="get-access-btn"
          >
            Get started now
          </a>
        </div>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Menu from "../Menu";
import FAQ from "../FAQ";
import Features from "../Features";
import Footer from "../Footer";
import PackContainer from "../PackContainer.vue";

import {track} from "../../scripts/gtag";
import VideoModal from "../VideoModal";

export default {
  components: {
    Menu,
    FAQ,
    Features,
    Footer,
    PackContainer,
    VideoModal
  },

  data() {
    return {
      isModalOpened: false,
    }
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },
    toggleModal() {
      this.isModalOpened = !this.isModalOpened;
      if (this.isModalOpened) {
        // document.body.classList.add('modal-open');
        let x=window.scrollX;
        let y=window.scrollY;
        window.onscroll=function(){window.scrollTo(x, y);};

      } else {
        // document.body.classList.remove('modal-open');
        window.onscroll=function(){};

      }
    }
  },
};
</script>

<style lang="scss">
body.modal-open {
  overflow: hidden;
}

#improve-sales {
  .header {
    padding-bottom: 143px;
    background-image: url(../../assets/images/improve_sales/improve_sales_header_bg.png);
    background-position: 0 0;
    background-size: 100%;
    background-color: #f6f9ff;

    .title-wrapper {
      width: 71.1%;
      margin: 0 auto 213px;

      .title {
        @include heading1_black;
        width: 480px;
        margin-bottom: 33px;

        .title__purple {
          @include heading1_purple;
        }
      }

      .buttons-wrapper {
        display: flex;

        .get-access-btn {
          @include main-btn__black;
          text-decoration: none;

          padding: 15px 44px;
          margin-right: 24px;

          &:hover {
            @include main-btn_hover;
          }

          &:focus {
            @include main-btn_focus;
          }
        }

        .play_btn_image {
          width: 56px;
          height: 56px;
          background-image: url('./../../assets/images/btn_how_it_works.png');
          cursor: pointer;
          position: relative;
          display: inline;
        }
      }
    }

    .card-wrapper {
      display: flex;
      justify-content: center !important;
      width: 71.1%;
      margin: 0 auto;

      .card {
        position: relative;
        width: 502px;
        height: 274px;
        padding: 40px 33px 38px 40px;
        border-radius: 20px;
        backdrop-filter: blur(100px);

        .card-title {
          width: 130px;
          margin-bottom: 21px;
          font-family: $baseFontFamily;
          font-weight: bold;
          font-size: 32px;
          line-height: 40px;
          color: #18191f;
        }

        .card-text {
          font-family: $baseFontFamily;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #18191f;
        }

        &::after {
          position: absolute;
          content: "";
          top: 38px;
          right: 40px;
          width: 80px;
          height: 80px;
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .bg-yellow {
        background-color: rgba(255, 228, 115, 0.5);

        &::after {
          background-image: url(../../assets/images/improve_sales/improve_sales_card_image1.png);
        }
      }

      .bg-blue {
        background-color: rgba(157, 222, 244, 0.5);

        &::after {
          background-image: url(../../assets/images/improve_sales/improve_sales_card_image2.png);
        }
      }
    }
  }

  .main {
    .free-propositions {
      padding: 108px 0;
      background-color: rgba(195, 167, 255, 0.5);

      .wrapper {
        width: 71.1%;
        margin: 0 auto;

        .title {
          @include heading2_black;
          width: 470px;
          margin-bottom: 13px;

          .title__purple {
            @include heading2_purple;
          }
        }

        .text {
          @include main-text;

          width: 510px;
          margin-bottom: 56px;
        }

        .get-access-btn {
          @include main-btn__black;
          text-decoration: none;

          padding: 15px 44px;

          &:hover {
            @include main-btn_hover;
          }

          &:focus {
            @include main-btn_focus;
          }
        }
      }
    }

    .container {
      background-image: url(../../assets/images/improve_sales/improve_sales_container_bg.png);
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      overflow-x: hidden;

      #pack-container {
        margin-bottom: 203px;
      }
    }

    .ready-for-success {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 125px 0 122px;
      background-color: #dcd0ff;

      .title {
        @include heading2_black;
        text-align: center;
        width: 500px;
        margin-bottom: 32px;

        .title__purple {
          @include heading2_purple;
        }
      }

      .get-access-btn {
        @include main-btn__black;
        text-decoration: none;

        padding: 15px 44px;

        &:hover {
          @include main-btn_hover;
        }

        &:focus {
          @include main-btn_focus;
        }
      }
    }
  }
}

@media screen and (min-width: 360px) {
  #improve-sales {
    .header {
      padding-bottom: 84px;
      background-image: url(../../assets/images/header_bg/header_bg_360.png);
      background-size: 100% 100%;

      .title-wrapper {
        width: 91%;
        margin: 0 auto 333px;

        .title {
          @include heading2_black;

          width: 320px;
          margin-bottom: 32px;

          .title__purple {
            @include heading2_purple;
          }
        }
      }

      .card-wrapper {
        flex-direction: column;
        width: 100%;

        .card {
          height: auto;
          margin-bottom: 20px;
        }

        .bg-yellow {
          width: 351px;
          padding: 40px 33px 35px 28px;
          border-radius: 0 20px 20px 0;

          &::after {
            right: 19px;
            top: 25px;
            background-size: 80px 80px;
          }
        }

        .bg-blue {
          align-self: flex-end;
          width: 351px;
          padding: 40px 33px 35px 28px;
          margin: 0;
          border-radius: 20px 0 0 20px;


          &::after {
            top: 41px;
            right: 40px;
          }
        }
      }
    }

    .main {
      .free-propositions {
        padding: 109px 0 474px;
        height: 1000px;

        .box {
          position: absolute;
          width: 286.78px;
          height: 286.78px;
          top: 1900px;
          left: -148px;
          background-image: url("../../assets/images/free_proposition_images/box.png");
        }

        .package {
          position: absolute;
          width: 200px;
          height: 350px;
          top: 2150px;
          right: 0;
          background-image: url("../../assets/images/free_proposition_images/package.png");
          background-repeat: no-repeat;
        }

        .wrapper {
          width: 91%;

          .title {
            width: 328px;
            font-size: 32px;
            line-height: 40px;

            .title__purple {
              font-size: 32px;
              line-height: 40px;
            }
          }

          .text {
            width: 328px;
            margin-bottom: 54px;
          }
        }
      }

      .container {
        background-image: url(../../assets/images/improve_sales/improve_sales_container_bg_360.png);
        background-size: 100% 100%;
        background-position: 0 0;

        #features {
          margin-bottom: 112px;
        }

        #pack-container {
          margin-bottom: 42px;
        }

        #faq {
          padding: 91px 0 102px;
        }
      }

      .ready-for-success {
        padding: 67px 0 65px;

        .title {
          width: 315px;
          font-size: 32px;
          line-height: 40px;

          .title__purple {
            font-size: 32px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 510px) {
  #improve-sales {
    .header {
      padding-bottom: 100px;
      background-image: url(../../assets/images/header_bg/header_bg_510.png);

      .start_selling_image {
        position: absolute;
        width: 232px;
        height: 292px;
        top: 256px;
        right: 0;
        background-image: url(../../assets/images/start_selling/start_selling_boxes_560.png);
        background-repeat: no-repeat;
      }

      .title-wrapper {
        .title {
          width: 394px;
          margin-bottom: 32px;
        }
      }

      .card-wrapper {
        flex-wrap: wrap;
        width: 91%;

        .card {
          width: 100%;
          height: auto;
          margin-bottom: 16px;
          border-radius: 20px;
          margin-right: 0;
        }

        .bg-yellow {
          &::after {
            top: 40px;
            right: 42px;
            background-size: 80px 80px;
          }
        }
      }

      .buttons-wrapper {
        .play_btn_image {
          &:hover {
            background-image: url('./../../assets/images/btn_how_it_works_hover.png');
          }
          &:hover:after{
            @include tooltip_for_video;
          }
        }
      }
    }

    .main {
      .free-propositions {
        padding: 109px 0;

        .box {
          top: 1850px;
          left: 50px;
        }

        .package {
          top: 2100px;
          right: 0;
        }

        .blue {
          position: absolute;
          width: 40px;
          height: 40px;
          left: 50px;
          top: 2350px;
          background-image: url("../../assets/images/free_proposition_images/blue.png");
        }

        .wrapper {
          width: 91%;

          .title {
            width: 328px;
            font-size: 32px;
            line-height: 40px;

            .title__purple {
              font-size: 32px;
              line-height: 40px;
            }
          }

          .text {
            width: 328px;
            margin-bottom: 54px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #improve-sales {
    .header {
      background-image: url(../../assets/images/header_bg/header_bg_768.png);

      .start_selling_image {
        position: absolute;
        width: 319px;
        height: 672px;
        top: 40px;
        right: 0;
        background-image: url(../../assets/images/start_selling/start_selling_boxes_768.png);
        background-repeat: no-repeat;
        background-size: cover;
      }

      .orange {
        position: absolute;
        width: 48px;
        height: 48px;
        left: 0;
        top: 500px;
        background-image: url(../../assets/images/circles/orange_circle_48.png);
      }

      .title-wrapper {
        //margin: 0 auto 573px;
        .title {
          //color: red;
          width: 602px;
          z-index: 1;
          position: relative;
          @include heading1_black;

          .title__purple {
            @include heading1_purple
          }
        }
      }

      .card-wrapper {
        flex-direction: row;
        justify-content: space-between !important;
        width: 100%;

        .card {
          width: 374px;
          height: 345px;
        }

        .bg-yellow {
          padding: 38px 38px 38px 46px;
          margin-right: 0;
          border-radius: 0 20px 20px 0;
        }

        .bg-blue {
          padding: 38px 38px 38px 46px;
          border-radius: 20px 0 0 20px;
        }
      }
    }

    .main {
      .ready-for-success {
        width: 100%;
        //padding: 122px 0 122px;
        .title {
          width: 589px;
          @include heading2_black;

          .title__purple {
            @include heading2_purple;
          }
        }
      }

      .free-propositions {
        padding: 109px 0;
        height: 500px;

        .package {
          display: none;
        }

        .box {
          width: 250px;
          height: 250px;
          top: 1150px;
          left: 500px;
        }

        .blue {
          left: 560px;
          top: 1550px;
        }

        .orange {
          position: absolute;
          left: 427px;
          top: 1500px;
          width: 24px;
          height: 24px;
          background-image: url("../../assets/images/free_proposition_images/orange.png");
        }

        .wrapper {
          .title {
            width: 502px;
            font-size: 48px;
            line-height: 56px;

            .title__purple {
              font-size: 48px;
              line-height: 56px;
            }
          }

          .text {
            width: 502px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  #improve-sales {
    .header {
      background-image: url(../../assets/images/header_bg/header_bg_1024.png);
      padding-bottom: 160px;

      .start_selling_image {
        width: 479px;
        height: 1008px;
        top: 0;
        right: 0;
      }

      .orange {
        left: 40px;
        top: 500px;
      }

      .blue {
        position: absolute;
        width: 32px;
        height: 32px;
        left: 471px;
        top: 370px;
        background-image: url(../../assets/images/circles/blue_circle_32.png);
      }

      .title-wrapper {
        width: 91.8%;
        margin: 0 auto 670px;

        .title {
          width: 589px;
          @include heading1;

          .title__purple {
            @include heading1;
          }
        }
      }

      .card-wrapper {
        width: 95%;
        justify-content: center !important;

        .card {
          width: 460px;
          height: 300px;
        }

        .bg-yellow {
          border-radius: 20px;
          margin-right: 20px;
        }

        .bg-blue {
          border-radius: 20px;
        }
      }
    }

    .main {
      .free-propositions-wrapper {
        .free-propositions {
          position: relative;

          .package {
            top: 157px;
            display: block;
          }

          .box {
            top: 0;
            left: 562px;
          }

          .blue {
            left: 584px;
            top: 367px;
          }

          .orange {
            left: 451px;
            top: 312px;
          }

          .wrapper {
            width: 91.8%;

            .title {
              font-size: 48px;
              line-height: 56px;


              .title__purple {
                font-size: 48px;
                line-height: 56px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  #improve-sales {
    .header-wrapper {
      background-image: url(../../assets/images/header_bg/header_bg_1440.png);
      background-size: 100%;
      background-repeat: no-repeat;

      .header {
        max-width: 1440px;
        margin: 0 auto;
        background-image: none;
        background-color: inherit;
        position: relative;

        .start_selling_image {
          width: 691px;
          height: 1008px;
          top: 0;
          right: 0;
          background-image: url(../../assets/images/circles/start_selling_1440.png);
        }

        .orange {
          left: 54px;
          top: 500px;
        }

        .blue {
          left: 657px;
          top: 370px;
        }

        .small_purple {
          position: absolute;
          top: 1050px;
          left: 1155px;
          width: 24px;
          height: 24px;
          background-image: url(../../assets/images/circles/purple_circle_24.png);
        }

        .big_purple {
          position: absolute;
          width: 88px;
          height: 88px;
          top: 122px;
          right: 97px;
          background-image: url(../../assets/images/circles/purple_circle_88.png);
        }

        .yellow {
          position: absolute;
          top: 1000px;
          left: 1278px;
          width: 48px;
          height: 48px;
          background-image: url(../../assets/images/circles/yellow_circle_48.png);
        }

        .title-wrapper {
          width: 78%;
          margin: 0 auto 670px;
        }

        .card-wrapper {
          width: 78%;

          .card {
            width: 502px;
          }
        }
      }
    }

    .main {
      .free-propositions-wrapper {
        background-color: #DCD0FF;

        .free-propositions {
          max-width: 1440px;
          margin: 0 auto;
          position: relative;
          background-color: inherit;
          //background-image: none;

          .package {
            width: 318px;
            height: 318px;
            background-image: url('../../assets/images/start_selling/full_package.png');
          }

          .box {
            left: 858px;
          }

          .wrapper {
            width: 78%;

            .title {
              width: 505px;
            }

            .text {
              width: 505px;
            }
          }
        }
      }

      .container-wrapper {
        background-image: url(../../assets/images/improve_sales/improve_sales_container_bg_360.png);
        background-size: 100% 100%;
        background-position: 0 0;

        .container {
          max-width: 1440px;
          margin: 0 auto;
          background-image: none;
        }
      }

      .ready-for-success {
        .title {
          width: 589px;
          @include heading2_black;

          .title__purple {
            @include heading2_purple;
          }
        }
      }
    }

    .title {
      width: 328px;
      font-size: 32px;
      line-height: 40px;

      .title__purple {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .text {
      width: 328px;
      margin-bottom: 54px;
    }
  }
}

@media screen and (min-width: 1700px) {
  #improve-sales {
    .header-wrapper {
      .header {
        .start_selling_image {
          width: 930px;
          height: 1008px;
          top: -200px;
          right: -190px;
          background-image: url(../../assets/images/start_selling/full_boxes.png);
        }

        .big_purple {
          top: 100px;
          right: 330px;
        }
      }
    }
  }
}
</style>