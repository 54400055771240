<template>
  <section id="features">
    <div class="wrapper">
      <h2 class="title">Features</h2>
      <div class="features-block-wrapper">
        <div class="features__bg"></div>
        <ul class="features-list">
          <li
              class="feature"
              :class="{
            opened: feature.isOpened,
            remove_animation: !isFeatureAnimated,
          }"
              v-for="(feature, index) of features"
              :key="index"
              @click="featureToggle(index, true)"
              @mouseenter="clearFeatureTimer"
              @mouseleave="setFeatureTimer"
          >
            <h3 class="feature-title">{{ feature.title }}</h3>
            <p class="feature-text">{{ feature.text }}</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.setFeatureTimer();
  },

  destroyed() {
    this.clearFeatureTimer();
  },

  data() {
    return {
      featuresAutoplayTimer: "",

      isFeatureAnimated: true,

      features: [
        {
          title: "Storefront",
          text: "Create private web storefronts and start selling.",
          isOpened: true,
        },
        {
          title: "Customer package customization",
          text:
              "Customize any package template or let the customer apply own designs upon ordering.",
          isOpened: false,
        },
        {
          title: "Admin package creation",
          text:
              "Create packaging of any type and complexity: from classic mailer boxes to flexible food packaging.",
          isOpened: false,
        },
        {
          title: "Integration with third-party software",
          text:
              "Connect your storefront with any MIS, CRM, or any other software that has an API.",
          isOpened: false,
        },
        {
          title: "Dieline template",
          text:
              "Create a product in web editor and get a dieline template, or convert your template to a 3D box model.",
          isOpened: false,
        },
      ],
    };
  },

  methods: {
    featureToggle(index, isNativeClick = false) {
      if (isNativeClick) this.isFeatureAnimated = false;
      this.features = this.features.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            isOpened: true,
          };
        }
        return {
          ...el,
          isOpened: false,
        };
      });
    },

    featuresAutoplay() {
      const currentFeatureIndex = this.features.findIndex((el) => el.isOpened);
      const nextFeatureIndex =
          currentFeatureIndex === this.features.length - 1
              ? 0
              : currentFeatureIndex + 1;
      this.featureToggle(nextFeatureIndex);
    },

    clearFeatureTimer() {
      clearInterval(this.featuresAutoplayTimer);
      this.removeFeatureBorderAnimation();
    },

    setFeatureTimer() {
      this.featuresAutoplayTimer = setInterval(this.featuresAutoplay, 5000);
      this.addFeatureBorderAnimation();
    },

    addFeatureBorderAnimation() {
      this.isFeatureAnimated = true;
    },

    removeFeatureBorderAnimation() {
      this.isFeatureAnimated = false;
    },
  },
};
</script>

<style lang="scss">
#features {
  position: relative;
  padding-top: 104px;
  margin-bottom: 252px;

  .wrapper {
    width: 71.1%;
    margin: 0 auto;

    .title {
      @include heading2_black;
      margin-bottom: 36px;
    }

    .features-block-wrapper {
      display: flex;
      flex-direction: column;


      .features__bg {
        background-image: url(../assets/images/features_bg_desktop.png);
        background-size: 100% 100%;
      }

      .features-list {
        display: flex;
        flex-direction: column;
        width: 435px;
        list-style-type: none;
        padding: 0;

        .feature {
          position: relative;
          padding: 28px 76px 28px 32px;
          border-top: 1px solid #e8e8e8;
          cursor: pointer;

          transition: 0.4s ease;

          .feature-title {
            @include main-text;

            font-weight: bold;
          }

          .feature-text {
            @include main-text;
            max-height: 0;
            transition: 0.4s ease;
            overflow: hidden;
          }

          &::after {
            position: absolute;
            content: "";
            top: 29px;
            right: 32px;
            width: 24px;
            height: 24px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../assets/images/feature_arrow.svg);
            transition-duration: 0.3s;
          }
        }

        .feature:last-child {
          border-bottom: 1px solid #e8e8e8;
        }

        .opened {
          background-color: #ffffff;
          box-shadow: 0px 5px 30px rgba(144, 85, 255, 0.2);
          border-radius: 12px;
          overflow: hidden;

          .feature-text {
            @include main-text;
            max-height: 82px;
            padding-top: 7px;
            padding-bottom: 3px;
            width: 300px;
            transition: 0.4s ease;
          }

          &::before {
            position: absolute;
            content: "";
            top: 0px;
            left: 0px;

            width: 100%;
            height: 4px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #9055ff;
            border-radius: 12px 0 0 0;
            transition-duration: 0.3s;
            opacity: 0.5;
            animation: featureBorderAnimation 5s infinite;
          }

          &::after {
            transform: rotate(180deg);
          }
        }

        .remove_animation {
          &::before {
            animation: none;
          }
        }
      }
    }
  }
}

@keyframes featureBorderAnimation {
  0% {
    width: 87px;
  }

  100% {
    width: 100%;
  }
}

@media screen and (min-width: 360px) {
  #features {
    padding-top: 105px;
    margin-bottom: 20px;

    .wrapper {
      width: 91%;

      .title {
        margin-bottom: 39px;

        font-size: 32px;
        line-height: 40px;
      }

      .features-block-wrapper {
        .features__bg {
          background-image: url(../assets/images/features_bg_360.png);
          align-self: center;
          width: 328px;
          height: 202px;
          margin-bottom: 22px;

        }

        .features-list {
          width: 98%;

          .feature-text {
            max-height: none;
            animation: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 510px) {
  #features {
    .wrapper {
      .features-block-wrapper {
        .features__bg {
          margin-bottom: 33px;
          background-image: url(../assets/images/features_bg_510.png);
          width: 478px;
          height: 307px;
        }

        .features-list {
          width: 100%;

          .feature {
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
            margin-bottom: 32px;

            &::after {
              display: none;
            }

            &::before {
              display: none;
            }

            .feature-title {
              margin-bottom: 8px;
            }

            .feature-text {
              max-height: none;
              animation: none;
              width: 100%;
            }
          }

          .feature:last-child {
            border: none;
          }

          .opened {
            animation: none;

            .feature-text {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #features {
    padding-top: 104px;
    margin-bottom: 91px;

    .wrapper {
      width: 88%;

      .title {
        font-size: 48px;
        margin-bottom: 47px;
      }

      .features-block-wrapper {

        .features__bg {
          background-image: url(../assets/images/features_bg_768.png);
          background-size: cover;
          width: 676px;
          height: 416px;
          margin-bottom: 33px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  #features {
    padding-top: 230px;

    .wrapper {
      width: 100%;
      margin-left: 46px;

      .title {
        font-size: 48px;
      }

      .features-block-wrapper {
        flex-direction: row-reverse;
        justify-content: flex-end;

        .features__bg {
          align-self: flex-start;
          width: 860px;
          height: 530px;
          margin-left: 87px;
        }

        .features-list {
          display: flex;
          flex-direction: column;
          width: 435px;
          list-style-type: none;
          padding: 0;
          margin-right: 20px;

          .feature {
            position: relative;
            padding: 28px 76px 28px 32px;
            border-top: 1px solid #e8e8e8;
            cursor: pointer;
            margin-bottom: 0;

            transition: 0.4s ease;

            .feature-title {
              @include main-text;

              font-weight: bold;
            }

            .feature-text {
              @include main-text;
              max-height: 0;
              transition: 0.4s ease;
              overflow: hidden;
            }

            &::after {
              display: block;
              position: absolute;
              content: "";
              top: 29px;
              right: 32px;
              width: 24px;
              height: 24px;
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url(../assets/images/feature_arrow.svg);
              transition-duration: 0.3s;
            }
          }

          .feature:last-child {
            border-bottom: 1px solid #e8e8e8;
          }

          .opened {
            background-color: #ffffff;
            box-shadow: 0px 5px 30px rgba(144, 85, 255, 0.2);
            border-radius: 12px;
            overflow: hidden;
            width: 435px;

            .feature-text {
              @include main-text;
              max-height: 82px;
              padding-top: 7px;
              padding-bottom: 3px;
              width: 300px;
              transition: 0.4s ease;
            }

            &::before {
              display: block;
              position: absolute;
              content: "";
              top: 0px;
              left: 0px;

              width: 100%;
              height: 4px;
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-color: #9055ff;
              border-radius: 12px 0 0 0;
              transition-duration: 0.3s;
              opacity: 0.5;
              animation: featureBorderAnimation 5s infinite;
            }

            &::after {
              display: block;
              transform: rotate(180deg);
            }
          }

          .remove_animation {
            &::before {
              animation: none;
            }
          }
        }
      }

    }
  }
}

@media screen and (min-width: 1440px) {
  #features {
    .wrapper {
      margin-left: 208px;
    }
  }
}

@media screen and (min-width: 1700px) {
  #features {
    .wrapper {
      margin-left: 160px;
      .features-block-wrapper {
        .features__bg {
          margin-left: -6px;
        }
      }
    }
  }
}

</style>
