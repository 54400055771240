<template>
  <div class="card" :style="{ backgroundColor: backgroundColor }">
    <div class="card-content">
      <img
          :src="require(`@/assets/images/${image}.png`)"
          :alt="image"
          class="card-image"
      />
      <h3 class="card-title">{{ title }}</h3>
      <p class="card-text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    backgroundColor: String,
    title: String,
    text: String,
  },
};
</script>

<style lang="scss">
.card {
  width: 310px;
  //height: 100%;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 20px;

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-image {
      width: 80px;
      height: 80px;
      margin-bottom: 19px;
    }

    .card-title {
      margin-bottom: 21px;
      font-family: $baseFontFamily;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #18191f;
    }

    .card-text {
      @include main-text;
    }
  }
}

@media screen and (min-width: 360px) {
  .card {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

@media screen and (min-width: 510px) {
  .card {
    width: 232px;

    .card-content {
      .card-text {
        width: 175px;
      }
    }
  }
  .card:nth-child(1) {
    margin-right: 16px;
  }
  .card:nth-child(3) {
    margin-left: 16px;
  }
}

@media screen and (min-width: 768px) {
  .card {
    width: 322px;
    .card-content {
      .card-text {
        width: 242px;
      }
    }
  }
  .card:nth-child(1) {
    margin-right: 32px;
  }
  .card:nth-child(3) {
    margin-left: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .card {
    width: 300px;
  }
}

@media screen and (min-width: 1440px) {
  .card {
    width: 310px;
  }
  .card:nth-child(1) {
    margin-right: 47px;
  }
  .card:nth-child(3) {
    margin-left: 47px;
  }
}


</style>
