<template>
  <footer id="footer">
    <div class="footer-wrapper">
      <div class="wrapper">
        <img
          src="../assets/images/logo_image.png"
          alt="logo"
          class="logo__image"
          @click="routeToStartPage"
        />
      </div>
      <div class="wrapper">
        <ul class="pages">
          <router-link to="/improve-sales">
            <li
              class="pages__item"
              @click="trackClick('click Improve online sales link')"
            >
              Improve online sales
            </li>
          </router-link>
          <router-link to="/start-selling">
            <li
              class="pages__item"
              @click="trackClick('click Start selling boxes online link')"
            >
              Start selling online
            </li>
          </router-link>
        </ul>

        <ul class="anchors">
          <span
            class="link-anchor"
            @click="
              () => {
                routeToAnchor('#about');
                trackClick('click About anchor');
              }
            "
            tabindex="0"
          >
            <li class="anchor">
              About
            </li>
          </span>
          <span
            class="link-anchor"
            @click="
              () => {
                routeToAnchor('#faq');
                trackClick('click F.A.Q. anchor');
              }
            "
            tabindex="0"
          >
            <li class="anchor">
              F.A.Q.
            </li>
          </span>
          <router-link to="get-in-touch">
            <li
              class="anchor"
              @click="trackClick('click Contact Sales anchor')"
            >
              Contact Sales
            </li>
          </router-link>
          <router-link to="/privacy-policy">
            <li
              class="anchor"
              @click="trackClick('click Privacy Policy anchor')"
            >
              Privacy Policy
            </li>
          </router-link>
        </ul>
      </div>
      <div class="wrapper">
        <p class="copyright">© 2020 3D Pack Pro. All rights reserved</p>
      </div>
    </div>
    <button
      class="back-to-top"
      @click="scrollToTop"
      v-show="isBackToTopVisible"
    >
      Back to top
    </button>
  </footer>
</template>

<script>
import { track } from "../scripts/gtag";

export default {
  data() {
    return {
      isBackToTopVisible: false,
    };
  },

  mounted() {
    document.addEventListener("scroll", this.pageScroll);
  },

  destroyed() {
    document.removeEventListener("scroll", this.pageScroll);
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    pageScroll() {
      const BACK_TO_TOP_BTN_VISIBILITY_POINT = 1000;

      if (
        document.body.scrollTop > BACK_TO_TOP_BTN_VISIBILITY_POINT ||
        document.documentElement.scrollTop > BACK_TO_TOP_BTN_VISIBILITY_POINT
      ) {
        this.isBackToTopVisible = true;
      } else {
        this.isBackToTopVisible = false;
      }
    },

    routeToStartPage() {
      this.$router.push({ path: "/" }).catch((err) => {});
    },

    routeToAnchor(id) {
      const element = document.querySelector(id);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$router.push({
          path: `/improve-sales${id}`,
        });
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss">
#footer {
  position: relative;
  padding: 38px 0 115px;
  background-color: #0b0d17;
  @include footer-text;

  .footer-wrapper {
    width: 71.1%;
    max-width: 1440px;
    margin: 0 auto;

    .wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .logo__image {
        width: 48px;
        height: 48px;
        margin-bottom: 25px;
        cursor: pointer;
      }

      ul {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 16px;
        list-style-type: none;
      }

      a,
      .link-anchor {
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: #9055ff;
        }

        &:focus {
          color: #9055ff;
          outline: none;
        }
      }

      .pages {
        width: 343px;
      }

      .anchors {
        width: 407px;
      }

      .copyright {
        font-size: 13px;
        line-height: 20px;
        color: #929aab;
      }
    }
  }

  .back-to-top {
    position: fixed;
    bottom: 42px;
    right: 40px;

    padding-left: 30px;
    width: 168px;
    height: 48px;

    background-color: rgba(144, 85, 255, 0.5);
    border: 1px solid transparent;
    outline: none;
    backdrop-filter: blur(40px);
    border-radius: 64px;

    font-family: $baseFontFamily;
    font-weight: bold;
    font-size: 16px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;

    color: #ffffff;

    &::after {
      @include btn-after-arrow;

      left: 15px;
      transform: translateY(-50%) rotate(-90deg);
    }

    &:hover {
      background-color: rgba(126, 57, 255, 0.5);
    }

    &:focus {
      background-color: rgba(113, 38, 255, 0.5);
    }
  }
}



@media screen and (max-width: 1024px) {
  #footer {
    .footer-wrapper {
      width: 91.8%;
    }
  }
}

@media screen and (max-width: 869px) {
  #footer {
    padding: 63px 0 115px;

    .footer-wrapper {
      width: 88%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .wrapper {
        flex-wrap: wrap;

        .pages {
          width: 343px;
        }
      }
    }
  }
}

@media screen and (max-width: 510px) {
  #footer {
    padding: 60px 0;

    .footer-wrapper {
      width: 91%;

      .wrapper {
        .pages {
          flex-wrap: wrap;
          margin-bottom: 32px;
          width: 300px;

          .pages__item {
            margin-bottom: 16px;
          }
        }

        .anchors {
          flex-wrap: wrap;
          width: 252px;

          .link-anchor:nth-child(2) {
            margin-right: 130px;
            margin-bottom: 16px;
          }
        }
      }
    }

    .back-to-top {
      bottom: 17px;
      right: 16px;
      display: block;
      width: 67px;
      height: 67px;
      border-radius: 50%;

      font-size: 0;
      background-image: url(../assets/images/back_to_top_label.svg);
      background-repeat: no-repeat;
      background-position: center;

      &::after {
        display: none;
      }
    }
  }
}
</style>
