<template>
  <div id="pack-container">
    <div class="title-wrapper">
      <h2 class="title">
        Now you choose! <br/>
        <span class="title__purple">Rigid</span> or
        <span class="title__purple">flexible</span> packaging
      </h2>
      <p class="title__description">
        In our platform you can create and modify both cardboard boxes and
        flexible packaging. An intuitive interface will allow you to easily
        create any type of packaging and visualize the result.
      </p>
    </div>
    <div class="carousel-container">
      <carousel
          :navigationEnabled="true"
          :navigationNextLabel="' '"
          :navigationPrevLabel="' '"
          :paginationActiveColor="'#9694FF'"
          :paginationColor="'#c8cdd8'"
          :paginationSize="12"
          :paginationPadding="4"
          :autoplay="false"
          :autoplayHoverPause="true"
          :autoplayTimeout="3000"
          :minSwipeDistance="100"
          :perPageCustom="[
          [360, 1],
          [768, 2],
          [1121, 4]
        ]"
      >
        <slide v-for="(pack, i) of packs" :key="i">
          <img :src="pack.image" alt="pack image" class="slide-image"/>
          <p class="slide-title">{{ pack.title }}</p>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },

  data() {
    return {
      pageWidth: window.innerWidth,

      packs: [
        {
          title: "Box with lid",
          image: require("../assets/images/pack1.png"),
        },
        {
          title: "Box pouch",
          image: require("../assets/images/pack2.png"),
        },
        {
          title: "Pull out box",
          image: require("../assets/images/pack3.png"),
        },
        {
          title: "Flat pouch",
          image: require("../assets/images/pack4.png"),
        },
        {
          title: "Rollover hinged lid box",
          image: require("../assets/images/pack5.png"),
        },
        {
          title: "Doypack",
          image: require("../assets/images/pack6.png"),
        },
        {
          title: "Handle top box",
          image: require("../assets/images/pack7.png"),
        },
        {
          title: "Gusset bag",
          image: require("../assets/images/pack8.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#pack-container {
  width: 71.1%;
  margin: 0 auto;

  .title-wrapper {
    width: 665px;
    margin-bottom: 51px;

    .title {
      @include heading2_black;
      margin-bottom: 20px;

      .title__purple {
        @include heading2_purple;
      }
    }

    .title__description {
      width: 520px;

      @include main-text;
    }
  }

  .carousel-container {
    margin: 0 auto;

    .slide-image {
      width: 241px;
      height: 196px;
    }

    .slide-title {
      margin-top: 25px;
      margin-left: -21px;
      font-weight: bold;
      line-height: 24px;
      color: #929aab;
    }

    .VueCarousel {
      width: 1043px;
    }

    .VueCarousel-slide {
      display: flex;
      flex-direction: column;
      text-align: center;
      flex-basis: 261px;

      @include main-text;
    }

    .VueCarousel-navigation-button {
      top: 26%;
      width: 48px;
      height: 48px;
      background-image: url(../assets/images/big_carousel_arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px 24px;
      background-color: white;
      border-radius: 50%;
      transform: none;
      outline: none;
      display: none;
    }

    .VueCarousel-navigation-next {
      right: -48px;
    }

    .VueCarousel-navigation-prev {
      left: -68px;
      transform: rotate(180deg);
    }

    .VueCarousel-dot-container {
      margin-top: 7px !important;
      margin-left: -20px;
    }

    .VueCarousel-dot {
      outline: none;
    }
  }
}

@media screen and (min-width: 360px) {
  #pack-container {
    width: 88%;

    .title-wrapper {
      width: 328px;

      .title {
        width: 300px;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 23px;

        .title__purple {
          font-size: 32px;
        }
      }

      .title__description {
        width: 328px;
      }
    }

    .carousel-container {
      margin: 0;
      display: flex;
      justify-content: center;

      .slide-title {
        margin-top: 24px;
        margin-left: -47px;
      }

      .slide-image {
        width: 328px;
        height: 266px;
      }

      .VueCarousel {
        margin: 0;
        width: 348px;
      }

      .VueCarousel-slide {
        flex-basis: 348px;
      }

      .VueCarousel-dot {
        width: 8px !important;
        height: 8px !important;
        padding: 10px !important;
      }

      .VueCarousel-dot:last-child {
        display: initial;
      }

      .VueCarousel-dot-container {
        margin-top: 1px !important;
        margin-left: -22px;
      }
    }
  }
}

@media screen and (min-width: 510px) {
  #pack-container {
    width: 88%;

    .title-wrapper {
      width: 475px;

      .title, .title__description {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #pack-container {
    width: 88%;

    .title-wrapper {
      width: 676px;
      margin-bottom: 75px;

      .title {
        margin-bottom: 44px;
        @include heading2_black;
        .title__purple {
          @include heading2_purple;
        }
      }

      .title__description {
        width: 550px;
      }
    }

    .carousel-container {
      margin: 0;

      .slide-image {
        width: 328px;
        height: 266px;
      }

      .slide-title {
        margin-top: 22px;
      }

      .VueCarousel {
        width: 696px;
      }

      .VueCarousel-slide {
        flex-basis: 348px;
      }

      .VueCarousel-dot {
        width: 8px !important;
        height: 8px !important;
        padding: 10px !important;
      }


      .VueCarousel-dot-container {
        margin-top: 0px !important;
      }
    }
  }
}

@media screen and (min-width: 1121px) {
  #pack-container {
    .carousel-container {
      justify-content: flex-start;

      .slide-image {
        width: 241px;
        height: 196px;
        margin-right: 20px;
      }

      .slide-title {
        margin-top: 22px;
      }

      .VueCarousel {
        width: 1040px;
      }

      .VueCarousel-slide {
        flex-basis: 240px;
      }

      .VueCarousel-dot {
        width: 12px !important;
        height: 12px !important;
      }

      .VueCarousel-navigation-button {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  #pack-container {
    width: 71%;

    .title-wrapper {
      margin-bottom: 50px;

      .title {
        width: 700px;
        font-size: 48px;

        .title__purple {
          font-size: 48px;
        }
      }
    }
  }

}
</style>
