import Vue from "vue";
import VueRouter from "vue-router";
import StartPage from "../components/StartPage";
import StartSelling from "../components/StartSelling";
import ImproveSales from "../components/ImproveSales";
import GetInTouch from "../components/GetInTouch";
import PrivacyPolicy from "../components/PrivacyPolicy";

Vue.use(VueRouter);

const routes = [
  {
    path: `/`,
    name: "StartPage",
    component: StartPage,
  },
  {
    path: `/start-selling`,
    name: "StartSelling",
    component: StartSelling,
  },
  {
    path: `/improve-sales`,
    name: "ImproveSales",
    component: ImproveSales,
  },
  {
    path: `/get-in-touch`,
    name: "GetInTouch",
    component: GetInTouch,
  },
  {
    path: `/privacy-policy`,
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
