<template>
  <section id="faq">
    <div class="blue"></div>
    <div class="yellow"></div>
    <div class="purple"></div>
    <div class="orange"></div>
    <h2 class="faq__title">F.A.Q.</h2>
    <div class="faq-wrapper">
      <div
          class="question-block"
          :class="{ opened: question.isOpened }"
          v-for="(question, index) of questions"
          :key="index"
          @click="questionToggle(index)"
      >
        <p class="question">{{ question.question }}</p>
        <p class="answer" :class="{ visible: question.isOpened }">
          {{ questions[index].answer }}
        </p>
      </div>
    </div>
    <!-- <button class="load-more">Load more</button> -->
  </section>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          question: "How to Sign Up in Custom Box?",
          answer:
              "Fill out the feedback form and we will contact you to give you access to the platform or show a demo.",
          isOpened: false,
        },
        {
          question: "How much does it cost and what are the payment options?",
          answer:
              "The cost of the solution is calculated depending on your needs. We provide access to the platform to make boxes and flexible packages or we can also integrate technology into your existing infrastructure and provide access to the API.",
          isOpened: false,
        },
        {
          question: "How is the Custom Box connected to the web service?",
          answer:
              "We can integrate the solution into the site so that your customers can assemble the box they like and choose a design for it or integrate technology into your internal solution. We also provide access to the 3D Pack API.",
          isOpened: false,
        },
        {
          question: "What options will the client have for customization?",
          answer:
              "The client can assemble any box and get a scan in 2D and 3D dimensions. Our solution has all the necessary options: shapes, cuts, holes, etc., to get the desired product.  In addition, the client can create their own design using the built-in editor.",
          isOpened: false,
        },
        {
          question: "What options will the admin have?",
          answer:
              "The admin can create the boxes or flexible packages that he has available for sale and provides the client with a choice from the existing set of packaging.",
          isOpened: false,
        },
      ],
    };
  },

  methods: {
    questionToggle(index) {
      this.questions = this.questions.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            isOpened: !el.isOpened,
          };
        }
        return el;
      });
    },
  },
};
</script>

<style lang="scss">
#faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 53px 0 92px;

  background-repeat: no-repeat;
  background-size: 100%;

  .faq__title {
    @include heading2_black;

    margin-bottom: 47px;
  }

  .faq-wrapper {
    margin-bottom: 48px;
    padding: 38px 58px;

    background: #ffffff;
    box-shadow: 0 5px 30px rgba(144, 85, 255, 0.2);
    border-radius: 12px;

    .question-block {
      position: relative;
      padding: 20px 0;
      cursor: pointer;

      @include main-text;

      &:after {
        position: absolute;
        top: 20px;
        right: -1px;

        content: "";
        width: 24px;
        height: 24px;

        background-image: url(../assets/images/feature_arrow.svg);
        transition-duration: 0.4s;
      }

      .question {
        font-weight: bold;
      }

      .answer {
        max-height: 0;
        overflow: hidden;
        transition: 0.8s ease;

        padding: 8px 0 0;
      }

      .visible {
        transition: 0.8s ease;
        max-height: 104px;
      }
    }

    .opened {
      border-bottom: 1px solid #e8e8e8;

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .load-more {
    @include main-btn;

    padding: 12px 23px;

    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(40px);
    border: 1px solid transparent;

    color: #929aab;

    &:hover {
      @include gray-btn_hover;
    }

    &:focus {
      @include gray-btn_focus;
    }
  }
}

@media screen and (min-width: 360px) {
  #faq {
    .faq__title {
      margin-bottom: 44px;

      font-size: 32px;
      line-height: 40px;
    }

    .faq-wrapper {
      width: 328px;
      padding: 41px 33px;

      .question-block {
        .question {
          width: 215px;
        }

        .visible {
          max-height: 230px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #faq {
    background-image: none;

    .faq__title {
      font-size: 48px;
    }

    .faq-wrapper {
      width: 678px;
      padding: 41px 33px;

      .question {
        width: 500px !important;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  #faq {
    background-position-x: -208px;
    background-size: 1440px;
    position: relative;

    .blue {
      width: 75px;
      height: 75px;
      right: 70px;
      top: 53px;
      position: absolute;
      background-image: url("../assets/images/faq_circles/blue.png");
      background-repeat: no-repeat;
    }

    .yellow {
      position: absolute;
      width: 96px;
      height: 96px;
      left: -48px;
      top: 289px;
      background-image: url("../assets/images/faq_circles/yellow.png");
      background-repeat: no-repeat;
    }

    .purple {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 0;
      top: 430px;
      background-image: url("../assets/images/faq_circles/purple.png");
      background-repeat: no-repeat;
    }

    .orange {
      position: absolute;
      width: 48px;
      height: 48px;
      right: 250px;
      top: 670px;
      background-image: url("../assets/images/faq_circles/orange.png");
      background-repeat: no-repeat;
    }

    .faq-wrapper {
      padding: 58px;
    }
  }
}

@media screen and (min-width: 1440px) {
  #faq {
    background-position-x: -208px;
    background-size: 1440px;
    position: relative;

    .blue {
      right: 290px;
    }

    .yellow {
      left: 170px;
    }

    .purple {
      right: 208px;
    }

    .orange {
      right: 419px;
    }

    .faq-wrapper {
      padding: 58px;
    }
  }
}
</style>