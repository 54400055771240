<template>
  <div id="app">
    <router-view> </router-view>
    <Cookies />
  </div>
</template>

<script>
import Cookies from "./components/Cookies";

export default {
  name: "App",
  components: {
    Cookies,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// font-family: 'Poppins', sans-serif;

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  box-sizing: border-box;
}
</style>
