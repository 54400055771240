<template>
  <section id="privacy-policy">
    <header class="header">
      <Menu/>
      <div class="wrapper">
        <h1 class="title">Privacy Policy</h1>
        <p class="text">
          Our privacy practices are designed to provide a high level of
          protection for your personal data
        </p>
      </div>
    </header>
    <main class="main">
      <div class="description-wrapper">
        <p class="text">
          This Privacy Policy explains what information of yours will be
          collected by Custom Box when you access the website at
          www.3dpack.pro (the “website”), all related websites (subdomains),
          other services provided by Custom Box on which a link to this Privacy
          Policy is displayed, and all other communications with you through
          from written or oral means, such as email or phone. It also describes
          how the information will be used, how you can control the collection,
          correction and/or deletion of information and how to get in touch with
          us if you need. Our privacy practices are designed to provide a high
          level of protection for your personal data. We will not use or share
          your information with anyone except as described in this Privacy
          Policy. This Policy is incorporated into, and is subject to, the Terms
          and Conditions of use available on this website. Please read this
          Privacy Policy carefully to understand our views and practices
          regarding your personal data before browsing this website.
        </p>
      </div>
      <div class="content">
        <article class="content-part">
          <h3 class="title">How the information about You is used</h3>
          <p class="text">
            We use the information that we collect to operate and maintain our
            website and to provide the services and information that you
            request, to allow you to contact us, provide access to the requested
            whitepaper, respond to your comments, questions, and concerns, and
            for other purposes specified herein.<br/><br/>
            If you have contacted us via this website or otherwise, we may
            process your personal data you provided to us to be able to answer
            your questions, to organize the meeting, call, or other
            communication based on our legitimate interest.<br/><br/>
            We will send you updates on the latest products, event
            announcements, and thought leadership or notifications of company
            news, marketing offers. We are relying on your consent which you
            provide by ticking that box. If you have consented to receive
            marketing, you have a right to withdraw your consent to the
            marketing communication at any time. You may unsubscribe or update
            your email preferences at any time by following the instructions
            contained within the email or by sending an email to the address
            provided in the Get in touch section. You may manage your
            subscriptions and you may withdraw your consent to the marketing
            communication at any time.<br/><br/>
            We can also process your personal data if it is necessary for us to
            administer the contractual relationship between ourselves and our
            suppliers/clients in connection with the performance of a contract.
            If you are a representative of our current/potential client, vendor,
            the partner we may process your personal data to develop and/or
            maintain business relations and communications, to promote our new
            products, works and services, to invite you to the meetings, events
            and organize them based on our legitimate interest.<br/><br/>
            Where you are our existing client and we have an existing
            relationship with you, or we reasonably believe you can be our
            client, we will send you information about products and services,
            events or other material that will be relevant to your individual
            interests and process your personal information to make sure we send
            you only relevant, targeted information based on our legitimate
            interest. You can opt-out of this at any time by following the
            instructions contained within the email or by sending an email to
            the address provided in the Get in touch section.
          </p>
        </article>

        <article class="content-part">
          <h3 class="title">What information do we process</h3>
          <p class="text">
            We collect information about you when you submit the information
            through the form on the website, For example, we collect your full
            name, email, phone number, and additional information you provide us
            when you contacting us directly.<br/><br/>
            We collect your full name, email, phone number, additional
            information you provide us when sending us a message.<br/><br/>
            We may also collect, store and use any other personal data that you
            choose to send to us. In cases where it is specifically stated that
            some data is not mandatory, you are free not to submit this data.
            Our website is not directed to children and we do not knowingly
            collect personal data from children. If you are under 16 years of
            age (or any other age under the applicable law), then please do not
            use or access the website at any time or in any manner or contact us
            and provide the consent of your parent or legal guardian.
          </p>
        </article>

        <article class="content-part">
          <h3 class="title">Cookies</h3>
          <p class="text">
            We collect anonymous information from visits to our website to help
            us provide better customer service. For example, we record certain
            automatically-collected information, which includes IP address, web
            browser, OS, the web pages or sites visited just before or just
            after using the website, keep track of the domains from which you
            visit and we also measure your activity on the website, but we do so
            in ways that keep the information anonymous. Custom Box uses this
            data to analyze trends and statistics, to prepare analytics for
            business intelligence, to target our advertising, and to help us
            provide better customer service. Cookies can be used to provide you
            with tailored information from a website. You can determine if and
            how a cookie will be accepted by configuring your browser, which is
            installed in the computer you are using to access the website. If
            you desire, you can change those configurations in your browser to
            accept all cookies, to be notified when a cookie is sent, or to
            reject all cookies.
          </p>
        </article>

        <article class="content-part">
          <h3 class="title">Your rights</h3>
          <p class="text">
            If you wish to access or amend any personal data we hold about you,
            or to request that we delete any information about you may contact
            us as set forth in the Get in touch section. When technically
            feasible, Custom Box will transfer your personal data to you or
            transmit it directly to another controller. At any time, you may
            also object to the processing of your personal data for direct
            marketing purposes or ask us to restrict the processing of your
            personal data. If you believe your right to privacy granted by
            applicable data protection laws has been infringed upon, please
            contact us as set forth in the Get in touch section. You also have a
            right to lodge a complaint with data protection authorities.
          </p>
        </article>

        <article class="content-part">
          <h3 class="title">Data retention</h3>
          <p class="text">
            We keep your personal data for as long as it is necessary to do so
            to fulfill the purposes for which it was collected as described
            above. The criteria we use to determine data retention periods for
            personal data includes the following: retention in accordance with
            legal and regulatory requirements. We may retain and use your
            information in order to comply with our legal obligations, resolve
            disputes, prevent abuse.
          </p>
        </article>

        <article class="content-part">
          <h3 class="title">Security</h3>
          <p class="text">
            We endeavor to use appropriate technical and physical security
            measures to protect your personal data which is transmitted, stored,
            or otherwise processed by us, from accidental or unlawful
            destruction, loss, alteration, unauthorized disclosure of, or
            access.
          </p>
        </article>

        <article class="content-part">
          <h3 class="title">Changes to our Privacy Policy</h3>
          <p class="text">
            We reserve the right to change this Privacy Policy at any time at
            our sole discretion. If we make changes to this Privacy Policy they
            will be posted on the website so that you are always aware of what
            information we gather, how we might use that information, and in
            what circumstances we may disclose it.
          </p>
        </article>

        <article class="content-part">
          <h3 class="title">Contacting us</h3>
          <p class="text">
            In the event, you have any questions, comments, and requests about
            the information set out in this Privacy Policy, please contact us by
            submitting the form below.
          </p>
        </article>
      </div>
      <div class="form-wrapper">
        <form class="form" @submit.prevent="sendMail" v-if="!isSended">
          <div class="field">
            <input
                type="text"
                class="input"
                :class="{ 'input-error': emailError }"
                id="email"
                v-model="email"
                placeholder=" "
            />
            <label for="email">Email</label>
            <p class="input-error-block">{{ emailError }}</p>
          </div>
          <div class="field">
            <textarea
                id="message"
                class="input"
                :class="{ 'input-error': messageError }"
                cols="30"
                rows="4"
                v-model="message"
                placeholder=" "
            ></textarea>
            <label for="message">Message</label>
            <p class="input-error-block">{{ messageError }}</p>
          </div>

          <div class="recaptcha-wrapper">
            <vue-recaptcha
                :sitekey="recaptchaSiteKey"
                class="captcha"
                size="normal"
                @verify="mxVerify"
            ></vue-recaptcha>
            <p class="recaptcha-error-block">{{ recaptchaError }}</p>
          </div>

          <button
              type="submit"
              class="send-message"
              @click="trackClick('click Send message button')"
          >
            send message
          </button>
        </form>
        <div class="sended-wrapper" v-else>
          <h2 class="title">Thank You!</h2>
          <p class="text">We'll be in touch shortly.</p>
          <button class="accept-btn" @click="routeToStartPage">OK</button>
        </div>
      </div>
    </main>
    <Footer/>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

import Menu from "./Menu";
import Footer from "./Footer";
import {validateEmail, validateMessage} from "../scripts/validations";
import {track} from "../scripts/gtag";

export default {
  components: {
    Menu,
    Footer,
    VueRecaptcha,
  },

  data() {
    return {
      email: "",
      emailError: "",
      message: "",
      messageError: "",
      isSended: false,
      isValidFormFill: false,
      recaptcha: null,
      recaptchaError: "",
      recaptchaSiteKey: "6LdnorEaAAAAAM9IZThqiME5spZK7WLwGhRzApDl",
    };
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    routeToStartPage() {
      this.$router.push({path: `/`});
    },

    validateForm() {
      this.emailError = validateEmail(this.email)
          ? ""
          : "Enter valid email address";
      this.messageError = validateMessage(this.message) ? "" : "Enter message";
      this.recaptchaError = this.recaptcha ? "" : "Doesn't complete!";

      if (this.emailError || this.messageError || this.recaptchaError) {
        this.isValidFormFill = false;
      } else {
        this.isValidFormFill = true;
      }
    },

    sendMail() {
      this.validateForm();

      if (this.isValidFormFill) {
        fetch("https://app.b2bprint.pro/api/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: "Name field is empty",
            sendToEmail: "contact@3dpack.pro",
            email: this.email,
            message: `${this.message}<br><b style="color: #9055ff">3D PACK!</b>`,
          }),
        })
            .then((res) => res.json())
            .then((data) => {
              this.isSended = true;
            })
            .catch((err) => {
              this.recaptchaError =
                  "Somthing went wrong. Try to send a bit later";
            });
      }
    },

    mxVerify(response) {
      this.recaptcha = response;
    },
  },
};
</script>

<style lang="scss">
#privacy-policy {
  .header {
    padding-bottom: 86px;

    background-image: url(../assets/images/privacy_policy_header_bg.png);
    background-size: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;

    #menu {
      margin: 0 auto 134px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      width: 71.2%;
      margin: 0 auto;

      .title {
        @include heading1_black;
        margin-bottom: 13px;
      }

      .text {
        @include main-text;
        width: 435px;
      }
    }
  }

  .main {
    background-color: #F6F9FF;
    .description-wrapper {
      background-color: #d8f2fb;
      padding: 65px 0 62px;

      .text {
        @include main-text;
        width: 71.2%;
        margin: 0 auto;
      }
    }

    .content {
      width: 71.2%;
      margin: 0 auto;
      padding-top: 147px;

      .content-part {
        margin-bottom: 96px;

        .title {
          @include heading3_black;

          margin-bottom: 13px;
        }

        .text {
          @include main-text;
        }
      }
    }

    .form-wrapper {
      margin-top: 96px;
      padding: 60px 0 76px;
      background-color: #dcd0ff;

      .form {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 435px;

        .field {
          position: relative;
          margin-bottom: 24px;

          .input {
            @include main-text;

            padding: 23px 12px 5px 12px;
            width: 100%;
            min-height: 56px;
            background-color: #f7f7f7;
            border: 2px solid #f7f7f7;
            outline: none;
            border-radius: 12px;
            resize: none;
          }

          .input-error-block {
            position: absolute;
            bottom: -20px;
            left: 0;

            font-family: $baseFontFamily;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: #f24833;
          }

          .input:-webkit-autofill,
          .input:-webkit-autofill:hover,
          .input:-webkit-autofill:focus,
          .input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
          }

          textarea {
            height: 112px;
          }

          label {
            position: absolute;
            top: 17px;
            left: 12px;

            font-family: $baseFontFamily;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #929aab;
          }

          .input:focus {
            border: 2px solid #9055ff;
          }

          .input:focus + label,
          .input:not(:placeholder-shown) + label {
            top: 6px;
            left: 14px;
            font-size: 13px;
            line-height: 19px;
          }

          .input-error {
            border: 2px solid #f24833 !important;
          }
        }

        .recaptcha-wrapper {
          position: relative;

          .captcha {
            margin-bottom: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .recaptcha-error-block {
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);

            font-family: $baseFontFamily;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: #f24833;
          }
        }

        .send-message {
          @include main-btn__black;
          width: 240px;
          margin: 0 auto;
          padding: 15px 58px;

          &:hover {
            @include main-btn_hover;
          }

          &:focus {
            @include main-btn_focus;
          }
        }
      }

      .sended-wrapper {
        width: 502px;
        margin: 0 auto 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin-bottom: 12px;
          @include heading2_black;
        }

        .text {
          @include main-text;

          margin-bottom: 32px;
        }

        .accept-btn {
          @include main-btn__black;

          padding: 15px 108px;

          &:hover {
            @include main-btn_hover;
          }

          &:focus {
            @include main-btn_focus;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 360px) {
  #privacy-policy {
    .header {
      padding-bottom: 71px;
      background-image: url(../assets/images/privacy_policy_header_bg_360.png);
      background-size: 100%;
      background-position: 0 0;

      #menu {
        margin: 0 auto 71px;
      }

      .wrapper {
        width: 91%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          width: 328px;
        }
      }
    }

    .main {
      .description-wrapper {
        padding: 86px 0 85px;

        .text {
          width: 91%;
        }
      }

      .content {
        padding-top: 79px;
        width: 91%;

        .content-part {
          margin-bottom: 79px;
        }

        .content-part:nth-child(4) {
          .title {
            margin-bottom: 2px;
          }
        }

        .content-part:last-child {
          margin-bottom: 49px;
        }
      }

      .form-wrapper {
        .form {
          width: 328px;

          .send-message {
            width: 328px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 510px) {
  #privacy-policy {
    .header {
      padding-bottom: 115px;
      background-image: url(../assets/images/privacy_policy_header_bg_510.png);
    }

    .content {
      padding-top: 80px;
      width: 91%;
    }

    .main {
      .form-wrapper {
        .form {
          width: 435px;

          .send-message {
            width: 240px;
          }
        }
      }
    }

  }
}
@media screen and (min-width: 76px) {
  #privacy-policy {
    .header {
      background-image: url(../assets/images/privacy_policy_header_bg_768.png);
      background-size: 100% 100%;
      #menu {
        margin: 0 auto 82px;
      }

      .wrapper {
        width: 88%;

        .title {
          @include heading2_black;
        }
      }
    }

    .main {
      .description-wrapper {
        .text {
          width: 88%;
        }
      }

      .content {
        width: 88%;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  #privacy-policy {
    .header {
      padding-bottom: 382px;
      background-image: url(../assets/images/privacy_policy_header_bg_1024.png);

      #menu {
        margin: 0 auto 355px;
      }

      .wrapper {
        width: 91.8%;
        text-align: start;
        align-items: flex-start;
        .text {
          width: 461px;
        }
      }
    }

    .main {
      .description-wrapper {
        .text {
          width: 91.8%;
        }
      }

      .content {
        width: 91.8%;
        padding-top: 148px;
      }

      .form-wrapper {
        .form {
          width: 460px;
        }
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  #privacy-policy {
    .header {
      padding-bottom: 60px;
      background-image: url(../assets/images/privacy_policy_header_bg_1440.png);
      background-size: 100%;
      background-position: 0 0;

      #menu {
        margin: 0 auto 134px;
      }

      .wrapper {
        text-align: center;
        align-items: center;
        .text {
          width: 461px;
        }
      }
    }

    .main {
      .description-wrapper {
        .text {
          max-width: 1024px;
        }
      }

      .content {
        max-width: 1024px;
      }

      .form-wrapper {
        .form {
          width: 435px;
        }
      }
    }
  }
}



</style>
