<template>
  <div id="get-in-touch">
    <div class="container">
      <Menu/>
      <div class="back-link-wrapper">
        <div class="back-link" @click="routeBack">BACK</div>
      </div>
      <div class="get-in-touch__wrapper" v-if="!isSended">
        <h2 class="title">Get in touch</h2>
        <p class="text">
          To request a quote or contact us fill out the form and we will get
          back to you promptly.
        </p>
        <form class="form" @submit.prevent="sendMail">
          <div class="field">
            <input
                type="text"
                class="input"
                :class="{ 'input-error': nameError }"
                id="name"
                v-model="name"
                placeholder=" "
            />
            <label for="name">Your name</label>
            <p class="input-error-block">{{ nameError }}</p>
          </div>
          <div class="field">
            <input
                type="text"
                class="input"
                :class="{ 'input-error': phoneError }"
                id="phone"
                v-model="phone"
                placeholder=" "
            />
            <label for="phone">Phone (Optional)</label>
            <p class="input-error-block">{{ phoneError }}</p>
          </div>
          <div class="field">
            <input
                type="text"
                class="input"
                :class="{ 'input-error': emailError }"
                id="email"
                v-model="email"
                placeholder=" "
            />
            <label for="email">Email</label>
            <p class="input-error-block">{{ emailError }}</p>
          </div>
          <div class="field">
            <textarea
                id="message"
                class="input"
                :class="{ 'input-error': messageError }"
                cols="30"
                rows="4"
                v-model="message"
                placeholder=" "
            ></textarea>
            <label for="message">Message</label>
            <p class="input-error-block">{{ messageError }}</p>
          </div>
          <div class="agreement-wrapper">
            <input type="checkbox" id="agreement" v-model="isAgree"/>
            <label for="agreement" class="agreement-label form-desc">
              I agree to the
              <router-link to="/privacy-policy">
                Privacy Policy and Terms of Service
              </router-link>
            </label>
            <p class="input-error-block">{{ error }}</p>
          </div>
          <button
              type="submit"
              class="send-message"
              @click="trackClick('click Send message button')"
          >
            send message
          </button>
        </form>
      </div>
      <div class="get-in-touch_sended" v-else>
        <h2 class="title">Thank You!</h2>
        <p class="text">We'll be in touch shortly.</p>
        <button class="accept-btn" @click="routeBack">OK</button>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Menu from "./Menu";
import Footer from "./Footer";
import {
  validateEmail,
  validateName,
  validatePhone,
  validateMessage,
} from "../scripts/validations";
import {track} from "../scripts/gtag";

export default {
  components: {
    Menu,
    Footer,
  },

  data() {
    return {
      name: "",
      nameError: "",
      phone: "",
      phoneError: "",
      email: "",
      emailError: "",
      message: "",
      messageError: "",
      isAgree: false,
      error: "",
      isSended: false,
      isValidFormFill: false,
    };
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    validateForm() {
      this.nameError = validateName(this.name) ? "" : "Enter valid name";
      this.phoneError = validatePhone(this.phone)
          ? ""
          : "Enter valid phone number";
      this.emailError = validateEmail(this.email)
          ? ""
          : "Enter valid email address";
      this.messageError = validateMessage(this.message) ? "" : "Enter message";
      this.error = this.isAgree ? "" : "You should accept our Privacy Policy";

      if (
          this.nameError ||
          this.phoneError ||
          this.emailError ||
          this.messageError ||
          !this.isAgree
      ) {
        this.isValidFormFill = false;
      } else {
        this.isValidFormFill = true;
      }
    },

    sendMail() {
      this.validateForm();

      if (this.isValidFormFill) {
        fetch("https://app.b2bprint.pro/api/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            sendToEmail: "contact@3dpack.pro",
            // sendToEmail: "3glifeyy@gmail.com", // email for test
            email: this.email,
            message: `${this.message}${
                this.phone ? `<br/><b>Phone:</b> ${this.phone}` : ""
            }<br><b style="color: #9055ff">3D PACK!</b>`,
          }),
        })
            .then((res) => res.json())
            .then((data) => {
              this.isSended = true;
            })
            .catch((err) => {
              this.error = "Somthing went wrong. Try to send a bit later";
            });
      }
    },

    routeBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
#get-in-touch {
  .container {
    min-height: 100vh;
    //padding-bottom: 103px;
    background-image: url(../assets/images/get_in_touch_bg.png);
    background-size: 100% auto;
    //background-position: 0 0;
    background-repeat: no-repeat;

    .back-link-wrapper {
      width: 78%;
      margin: 0 auto;

      .back-link {
        position: relative;
        width: 69px;
        padding-left: 24px;
        margin-bottom: 20px;

        font-family: $baseFontFamily;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #929aab;
        cursor: pointer;

        &::before {
          position: absolute;
          top: 0;
          left: 0;

          content: "";
          width: 24px;
          height: 24px;
          background-image: url(../assets/images/back_link_arrow.svg);
          background-position: center;
          background-repeat: no-repeat;
        }

        display: block;
      }
    }

    .get-in-touch__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      width: 30.2%;

      .title {
        @include heading2_black;

        margin-bottom: 12px;
      }

      .text {
        @include main-text;
        text-align: center;
        width: 430px;
        margin-bottom: 31px;
      }

      .form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .field {
          position: relative;
          margin-bottom: 24px;

          .input {
            @include main-text;

            padding: 23px 12px 5px 12px;
            width: 100%;
            min-height: 56px;
            background-color: #f7f7f7;
            border: 2px solid #f7f7f7;
            outline: none;
            border-radius: 12px;
            resize: none;
          }

          .input-error-block {
            position: absolute;
            bottom: -20px;
            left: 0;

            font-family: $baseFontFamily;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: #f24833;
          }

          .input:-webkit-autofill,
          .input:-webkit-autofill:hover,
          .input:-webkit-autofill:focus,
          .input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
          }

          textarea {
            height: 112px;
          }

          label {
            position: absolute;
            top: 17px;
            left: 12px;

            font-family: $baseFontFamily;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #929aab;
          }

          .input:focus {
            border: 2px solid #9055ff;
          }

          .input:focus + label,
          .input:not(:placeholder-shown) + label {
            top: 6px;
            left: 14px;
            font-size: 13px;
            line-height: 19px;
          }

          .input-error {
            border: 2px solid #f24833 !important;
          }
        }

        .agreement-wrapper {
          position: relative;
          display: flex;
          padding-left: 20px;

          #agreement {
            visibility: hidden;
          }

          .agreement-label {
            @include main-text;

            a {
              color: inherit;
            }

            position: relative;
            margin-bottom: 28px;

            cursor: pointer;

            &:before {
              position: absolute;
              top: 2px;
              left: -31px;
              content: "";
              border: none;

              width: 18px;
              height: 18px;
              cursor: pointer;
              transition-duration: 0.1s;

              background: transparent;
              background-image: url(../assets/images/checkbox.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100% 100%;
            }
          }

          .input-error-block {
            position: absolute;
            bottom: 8px;
            left: 0;

            font-family: $baseFontFamily;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: #f24833;
          }

          #agreement:checked + .agreement-label:before {
            background-image: url(../assets/images/checkbox_marker.svg);
          }
        }

        .send-message {
          @include main-btn__black;
          width: 240px;
          margin: 0 auto;
          padding: 15px 58px;

          &:hover {
            @include main-btn_hover;
          }

          &:focus {
            @include main-btn_focus;
          }
        }
      }
    }

    .get-in-touch_sended {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-bottom: 12px;
        @include heading2_black;
      }

      .text {
        @include main-text;

        margin-bottom: 32px;
      }

      .accept-btn {
        @include main-btn__black;

        padding: 15px 108px;

        &:hover {
          @include main-btn_hover;
        }

        &:focus {
          @include main-btn_focus;
        }
      }
    }
  }
}

//@media screen and (min-width: 1024px) {
//  #get-in-touch {
//    .container {
//      padding-bottom: 165px;
//
//      background-size: 150% 120%;
//      background-position: -300px -30px;
//    }
//  }
//}
@media screen and (max-width: 1024px) {
  #get-in-touch {
    .container {
      padding-bottom: 165px;

      background-size: 1440px 120%;
      background-position: -300px -30px;

      .back-link-wrapper {
        width: 91.3%;
        margin: 0 auto;
        .back-link {
          display: block;
        }
      }

      .get-in-touch__wrapper {
        width: 45%;

        .text {
          width: 455px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #get-in-touch {
    .container {
      padding-bottom: 150px;
      background-position: -459px -30px;

      //#menu {
      //  margin: 0 auto 149px;
      //}

      .back-link-wrapper {
        width: 88.3%;
        margin: 0 auto;

        .back-link {
          display: block;
        }
      }

      .get-in-touch__wrapper {
        width: 56.8%;

        .text {
          width: 440px;
        }
      }
    }
  }
}

@media screen and (max-width: 870px){
  #get-in-touch {
    .container {
      .back-link-wrapper {
        width: 88.3%;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 521px) {

  #get-in-touch {
    .container {
      padding-top: 21px;
      padding-bottom: 75px;

      background-image: url(../assets/images/get_in_touch_bg_360.png);
      background-size: 100%;
      background-position: 0 0;

      #menu {
        display: none;
      }

      .back-link-wrapper {
        width: 91%;
        margin: 0 auto;

        .back-link {
          display: block;
        }
      }

      .get-in-touch__wrapper {
        width: 91%;

        .text {
          width: 328px;
        }

        .form {
          .send-message {
            width: 328px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  #get-in-touch {
    .container {
      padding-bottom: 165px;
      background-size: 150% 120%;
      background-position: -300px -30px;

      .back-link-wrapper {
        width: 78%;
        margin: 0 auto;
        .back-link {
          display: block;
        }
      }

      .get-in-touch__wrapper {
        width: 45%;

        .text {
          width: 455px;
        }
      }
    }
  }
}

</style>
