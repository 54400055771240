<template>
  <div id="cookies" v-if="!isAcceptedCookies">
    <div class="wrapper">
      <p class="cookies__text">
        We use cookies to ensure your best experience. Through your continued use
        of this site you accept this use. For more information, please see our
        <router-link to="/privacy-policy">Privacy Policy.</router-link>
      </p>
      <button class="accept-cookies" @click="acceptCookies">Confirm</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAcceptedCookies: localStorage.getItem("isAcceptedCookies") ?? false,
    };
  },

  methods: {
    acceptCookies() {
      localStorage.setItem("isAcceptedCookies", true);
      this.isAcceptedCookies = true;
    },
  },
};
</script>

<style lang="scss">
#cookies {
  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 32px;
    content: "";
    width: 48px;
    height: 48px;

    background-image: url(../assets/images/cookie.svg);
  }

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px 24px 24px 112px;
  width: 1072px;
  height: 96px;

  background-color: #fbeeb9;
  border-radius: 10px 10px 0 0;

  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 927px;

    .cookies__text {
      @include main-text;

      a {
        color: inherit;
      }

      text-transform: uppercase;
    }

    .accept-cookies {
      @include main-text;

      text-transform: uppercase;
      font-weight: bold;

      padding: 5px 39px;
      width: 154px;
      height: 48px;

      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(40px);
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      outline: none;
      cursor: pointer;

      &:hover {
        background-color: #ffe473;
      }

      &:focus {
        background-color: #ffde56;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #cookies {
    width: 940px;

    &::before {
      top: 46px;
    }

    .wrapper {
      width: 795px;

      .cookies__text {
        width: 530px;
      }

      .accept-cookies {
        padding: 10px 31px;
        width: 186px;
      }
    }
  }
}

@media screen and (max-width: 941px) {
  #cookies {
    width: 676px;
    height: 160px;

    .wrapper {
      width: 532px;

      .cookies__text {
        width: 305px;
      }
    }
  }
}

@media screen and (max-width: 681px) {
  #cookies {
    width: 91%;
    height: auto;
    .wrapper {
      width: auto;
      flex-direction: column;
      .cookies__text {
        width: 370px;
        padding-right: 20px;
        margin-bottom: 20px;
      }
      .accept-cookies {
        align-self: flex-end;
        width: 288px;
      }
    }
  }
}


@media screen and (max-width: 480px) {
  #cookies {
    padding: 20px;

    &::before {
      top: 43px;
      left: 20px;
    }

    .wrapper {
      flex-direction: column;

      .cookies__text {
        width: 288px;
        padding-left: 68px;
        margin-bottom: 19px;
      }

      .accept-cookies {
        width: 288px;
      }
    }
  }
}
</style>
