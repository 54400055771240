<template>
  <div class="modal" @click="close">
    <youtube
        v-if="pageSize >= 1024"
        :video-id="videoSrc"
        player-width="1150"
        player-height="650"
        @ready="ready"
        :player-vars="{ autoplay: 1 }"
        class="video"
    ></youtube>
    <youtube
        v-if="pageSize >= 768 && pageSize < 1024"
        :video-id="videoSrc"
        player-width="760"
        player-height="500"
        @ready="ready"
        :player-vars="{ autoplay: 1 }"
        class="video"
    ></youtube>
    <youtube
        v-if="pageSize >= 510 && pageSize < 768"
        :video-id="videoSrc"
        player-width="510"
        player-height="300"
        @ready="ready"
        :player-vars="{ autoplay: 1 }"
        class="video"
    ></youtube>
    <youtube
        v-if="pageSize >= 360 && pageSize < 510"
        :video-id="videoSrc"
        player-width="360"
        player-height="220"
        @ready="ready"
        :player-vars="{ autoplay: 1 }"
        class="video"
    ></youtube>
    <button class="close-btn" type="button" @click="close">
      &times;
    </button>
  </div>
</template>

<script>
export default {
  props: ['videoSrc'],
  data() {
    return {
      pageSize: window.innerWidth
    };
  },

  methods: {
    ready(event) {
      this.player = event.target;
    },

    close() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, 0.8);

  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    padding: 0;
    cursor: pointer;
    color: #fff;
    font-size: 48px;
    border: none;
    background: none;
    outline: none;
  }

  .video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
