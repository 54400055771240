<template>
  <div id="start-page" :class="isModalOpened ? 'overflowHidden' : 'overflowVisible'">
    <Menu/>
    <div class="start-page__wrapper">
      <h1 class="title">
        Wishing your packaging
        could sell <span class="title_purple">(better)</span> online?
      </h1>
      <div class="btn-wrapper">
        <router-link to="/start-selling" tabindex="-1">
          <button
              class="start-selling"
              @click="trackClick('click Start selling boxes online button')"
          >
            Start selling boxes online
          </button>
        </router-link>
        <router-link to="/improve-sales" tabindex="-1">
          <button
              class="improve-sales"
              @click="trackClick('click Improve my online sales button')"
          >
            Improve my online sales
          </button>
        </router-link>
      </div>
      <div class='video-image-wrapper'>
        <div class="video-image">
          <div class="play_btn_image" @click="toggleModal"></div>
        </div>
      </div>
      <VideoModal v-if="isModalOpened" @close-modal="toggleModal" video-src="Igydq7X8VQQ"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Menu from "./Menu";
import Footer from "./Footer";
import VideoModal from "./VideoModal";

import {track} from "../scripts/gtag";

export default {
  components: {
    Menu,
    Footer,
    VideoModal,
  },

  data() {
    return {
      isModalOpened: false,
    };
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    toggleModal() {
      this.isModalOpened = !this.isModalOpened;
      if (this.isModalOpened) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    },
  },
};
</script>

<style lang="scss">
body.modal-open {
  overflow: hidden;
}

#start-page {
  min-height: 100vh;
  background-image: url(../assets/images/start_page_bg.png);
  background-size: 100% 100%;

  #menu {
    margin: 0 auto 140px;
  }

  .start-page__wrapper {
    margin: 0 auto;
    width: 82%;
    padding-bottom: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      max-width: 843px;
      margin-bottom: 49px;
      text-align: center;

      @include heading1_black;

      .title_purple {
        @include heading1_purple;
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin: 0 auto 139px;

      .start-selling {
        width: 353px;
        height: 56px;
        margin-right: 32px;
        padding-left: 34px;
        text-align: left;

        @include main-btn__black;

        &:after {
          @include btn-after-arrow;
          right: 37px;
        }

        &:hover {
          @include main-btn_hover;
        }

        &:focus {
          @include main-btn_focus;
        }
      }

      .improve-sales {
        width: 353px;
        height: 56px;
        padding-left: 46px;
        text-align: left;

        @include main-btn__purple;

        &:after {
          @include btn-after-arrow;
          right: 46px;
        }

        &:hover {
          @include main-btn_hover;
        }

        &:focus {
          @include main-btn_focus;
        }
      }
    }

    .video-image-wrapper {
      display: flex;
      justify-content: center;

      .video-image {
        width: 970px;
        height: 512px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../assets/images/video_bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        .play_btn_image {
          top: 150px;
          left: 392px;
          overflow: hidden;
          width: 180px;
          height: 180px;
          border-radius: 50%;
          background-image: url('../assets/images/play_btn.png');
          background-size: 320px 320px;
          background-position: center;
          cursor: pointer;
          transition-duration: 0.3s;

          &:hover {
            transform: scale(1.04);
          }
        }
      }
    }

  }
}

@media screen and (max-width: 1024px) {
  #start-page {
    background-image: url(../assets/images/start_page_bg_768.png);

    #menu {
      margin: 0 auto 134px;
    }

    .start-page__wrapper {
      width: 91.8%;
      padding-bottom: 180px;

      .video-image-wrapper {
        .video-image {
          width: 675px;
          height: 356px;

          .play_btn_image {
            width: 112px;
            height: 112px;
            background-size: 224px 224px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 869px) {
  #start-page {
    #menu {
      margin: 0 auto 70px;
    }

    .start-page__wrapper {
      padding-bottom: 346px;

      .title {
        padding-bottom: 48px;
      }

      .btn-wrapper {
        width: 353px;
        height: 64px;
        flex-direction: column;

        .start-selling {
          height: 64px;
          margin-bottom: 32px;
          margin-right: 0;
        }

        .improve-sales {
          height: 64px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #start-page {
    .start-page__wrapper {
      .title {
        font-size: 48px;
        line-height: 64px;

        .title_purple {
          font-size: 48px;
          line-height: 64px;
        }
      }
    }
  }
}

@media screen and (max-width: 681px) {
  #start-page {

    .start-page__wrapper {
      padding-bottom: 226px;

      .video-image-wrapper {
        .video-image {
          width: 478px;
          height: 252px;

          .play_btn_image {
            width: 135px;
            height: 135px;
            background-size: 270px 270px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 448px) {
  #start-page {
    background-image: url(../assets/images/start_page_bg_360.png);

    .start-page__wrapper {
      width: 95%;
      padding-bottom: 82px;

      .title {
        @include heading2_black;

        .title_purple {
          @include heading2_purple;
        }
      }

      .btn-wrapper {
        width: 328px;

        .start-selling {
          width: 328px;

          &::after {
            right: 22px;
          }
        }

        .improve-sales {
          width: 328px;

          &::after {
            right: 22px;
          }
        }
      }

      .video-image-wrapper {
        .video-image {
          width: 328px;
          height: 173px;

          .play_btn_image {
            width: 79px;
            height: 79px;
            background-size: 160px 160px;
          }
        }
      }
    }
  }
}

</style>
